/* main tags */
.tags {
    width:290px;
	padding:20px;
    margin-bottom:8px;
}
.tags .title {
font-size: 18px;
    color: #999999;
    line-height: 2em;
}
.tags .tag {
	display: inline-block;
    text-align: center;
    padding: 0 7px;
    line-height: 22px;
    font-size: 12px;
    color: #f29b45;
    border: #f29b45 solid 1px;
    border-radius: 3px;
    margin-bottom: 8px;
    margin-right: 10px;
}
.tags .tag a {
    font-size: 12px;
    color: #f29b45;
}