* { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

$lato: lato-wf, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, "PingFang SC", "萍方-简", "Hiragino Sans GB", 冬青黑体, STXihei, 华文细黑, "Microsoft YaHei", YaHei, 微软雅黑体, sans-serif;

$system: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, "PingFang SC", "萍方-简", "Hiragino Sans GB", 冬青黑体, STXihei, 华文细黑, "Microsoft YaHei", YaHei, 微软雅黑体, sans-serif;


body { font-family: $system; }

a { color:$blue-2; cursor:pointer }

h1, h2, h3, h4, h5, h6 { color:$blue; margin-top:16px;
  &:first-child { margin-top:0 }
}
h3 { font-size:21px }
h4 { color:$blue; _font-size:16px; font-weight:700;
  span { font-weight:400; margin-left:10px }
}
.table h4 { font-size:14px; 
  span { margin-left:0 }
}
p, pre, blockquote { color:$grey; font-size:14px; font-weight:400; margin-top:12px; line-height:1.8 } 
.table { color:$grey; font-size:14px; font-weight:400; }

.content .body p { word-wrap:break-word }

blockquote { padding-left:15px; border-left: solid 3px $blue-2-50 }

h1, h2, h3, h4, h5, h6 {
  + p, + pre { margin-top:16px }
}
a.link { font-size:14px; font-weight:700; text-decoration:none }

ul li, ol li { color:$grey; font-size:14px; font-weight:400; margin-top:12px }
ul, ol { margin-top:12px; margin-left:20px }

.content .body {
  > div > *:first-child { margin-top:0 }
  p strong { display:block; margin-top:24px }
}

@include for-phone-smaller {
  h4 { font-size:15px }
  p, pre, blockquote { font-size:13px }
}