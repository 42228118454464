$grey: rgb(110, 112, 112);
$grey-50: rgba(110, 112, 112, .5);

$blue: rgb(70, 97, 173);
$green: rgb(141, 189, 133);
$green-70: rgba(141, 189, 133, .7);
$green-50: rgba(141, 189, 133, .5);
$green-40: rgba(141, 189, 133, .4);
$green-15: rgba(141, 189, 133, .15);
$green-10: rgba(141, 189, 133, .1);
$blue-2: rgb(73, 148, 200);
$blue-2-80: rgba(73, 148, 200, .8);
$blue-2-70: rgba(73, 148, 200, .7);
$blue-2-50: rgba(73, 148, 200, .5);
$blue-2-40: rgba(73, 148, 200, .4);
$blue-2-25: rgba(73, 148, 200, .25);
$blue-2-15: rgba(73, 148, 200, .15);

$grey-rgb69: rgb(69, 69, 69);
$grey-rgb170: rgb(170, 170, 170);
$grey-rgb240: rgb(240, 240, 240);




#container {
  .section {
    &.data {
      .navbar 
        { .menu a {
          &:hover { border-bottom-color:$blue-2-50; }
          &.active { border-bottom-color:$blue-2; }  
        }
      }
      .status .circle { background-color:$blue-2 }
      .breadcrumb { background-color:$blue-2 }
      .link { color:$blue-2;
        h4 { color:$blue-2; }
      }
      .template.inner {
        .locked { border: solid 1px $blue-2; 
        }
      }
      .icon .green { display:none }
      .close-x { &:before, &:after { background-color:$blue-2 } }
      form input[type=submit], .button, button:not(.link) { background-color:$blue-2 }
    }
    &.college {
      .navbar 
        { .menu a {
          &:hover { border-bottom-color:$blue-2-50; }
          &.active { border-bottom-color:$blue-2; }  
        }
      }
      .status .circle { background-color:$blue-2 }
      .breadcrumb { background-color:$blue-2 }
      .link { color:$blue-2;
        h4 { color:$blue-2; }
      }
      .template.inner {
        .locked { border: solid 1px $blue-2; }
      }
      .icon .green { display:none }
      // .icon .blue-2 { display:none }
      .close-x { &:before, &:after { background-color:$blue-2 } }
      form input[type=submit], .button, button:not(.link) { background-color:$blue-2 }
    }

  }
}