.cookies_policy{
    transition: all 0.3s ease-in-out;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
    opacity: 0;
    &.open{
        opacity: 1;
    }
    &.close{
        z-index: -1;
    }
    .wrapper{
        width: 782px;
        padding: 0 72px 72px 72px;
        background: #fff;
        left: 50%;
        margin-left:-391px ;
        top: 20%;
        position: absolute;
        border-radius: 10px;
        h2{
            font-size: 30px;
            color:  rgba(0, 0, 0, 0.6);
            letter-spacing: 0;
            width: 100%;
            text-align: left;
            margin-top: 64px;
            padding-bottom: 24px;
            border-bottom: 1px solid #E9ECF0;
        }
        p{
            font-size: 14px;
            color: #5F6464;
            letter-spacing: 0;
            line-height: 25px;
            padding-top: 24px;
            margin-bottom: 82px;
            a{
                color: #4460ac;
            }
        }
        .btns{
            height: 40px;
            div{
                float: right;
                width: 200px;
                height: 40px;
                margin-left: 16px;
                line-height: 40px;
                text-align: center;
                font-size: 14px;
                color: #2A2E2E;
                background: #F2F3F5;
                cursor: pointer;
                &.btn_accept{
                    background: #4460ac;
                    color: #fff;
                }
            }
        }
    }
    @media screen and (max-width: 720px){
        .wrapper{
            position:initial;
            margin: 0 16px;
            margin-top: 30%;
            width: calc(100vw - 32px);
            padding: 16px;
            h2{
                font-size: 22px;
                margin-top: 24px;
                padding-bottom: 14px;
            }
            p{
                padding-top: 14px;
                margin-bottom: 22px;
            }
            .btns{
                height: auto;
                div{
                    float: none;
                    width: 100%;
                    margin: 0;
                    margin-bottom: 14px;
                }
            }
        }
    }
    @media screen and (max-width: 320px){ // iphone 5
        .wrapper{
            margin-top: 70px;
            h2{
                font-size: 22px;
                margin-top: 0px;
                padding-bottom: 14px;
            }
            p{
                padding-top: 0px;
                margin-bottom: 22px;
            }
        }
    }
}