.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container {
  width: 100%;
  max-width:1100px;
  margin-right: auto;
  margin-left: auto;
}
.bg-white {
    background-color:white !important;
}
.bg-blue {
	background-color:#4994c8 !important;
}
.text-green {
    color:#8dbd85 !important;
}
.cursor-pointer {
  cursor:pointer;
}
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-primary:hover {
  color: #fff;
  background-color: #025ce2;
  border-color: #0257d5;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #025ce2;
  border-color: #0257d5;
  box-shadow: 0 0 0 0.2rem rgba(49, 132, 253, 0.5);
}

.btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0257d5;
  border-color: #0252c9;
}

.btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 132, 253, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:active:focus, .btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:active:focus, .btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info:disabled, .btn-info.disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:active:focus, .btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning:disabled, .btn-warning.disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:active:focus, .btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:active:focus, .btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light:disabled, .btn-light.disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:active:focus, .btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.5);
}

.btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #0d6efd;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline;
}

.btn-link:hover {
  color: #024dbc;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-top: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb a {
    color: inherit !important;
    text-decoration: none;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  color: #6c757d;
  /* content: "/"; */
  content: "";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}
/* 缩写 */
.flex-fill {
  flex: 1 1 auto !important;
}
/* flex */
.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}
/*
.d-flex
.d-inline-flex
.d-sm-flex
.d-sm-inline-flex
.d-md-flex
.d-md-inline-flex
.d-lg-flex
.d-lg-inline-flex
.d-xl-flex
.d-xl-inline-flex
*/
/* flex-direction */
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
/* flex wrap */
.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
/* justify-content */
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
/* align-items */
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}
/* align-content */
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}
/* align-self */
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
/* flex-grow */
.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}
/* order */
.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}
/* flex-shrink */
.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}



@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
/* home */
/* home title */
.home-title {
    width: 100%;
    font-size: 24px;
    padding-top: 12px;
    color: #333333;
}
.main-title {
	width: 100%;
    font-size: 24px;
    padding-top: 12px;
    color: #333333;
    margin-bottom:18px;
}
/* home categories */
.categories {
	display:flex;
	justify-content: space-around;
	padding-top:15px;
	padding-bottom:15px;
}
.categories .category {
	flex-grow:1;
	text-align: center;
    height: 54px;
    line-height: 54px;
    color: #FFFFFF;
    font-size: 16px;
    background: #6a7a86;
    font-weight:bold;
    margin-right:3px;
}
.categories .category:last-child {
    margin-right:0px;
}
.categories .category:hover {
	background-color:#8dbd85;
}
.categories .category a {
    color:white;
}
.categories .active {
	background-color:#8dbd85;
}
/* model */
.models {

}
.models .model {
	flex-basis:calc(50% - 7.5px);
	padding:30px;
	margin-top:15px;
}
.models .model .title {
	color: #8dbd85;
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 20px;
}
.models .model a {
	width: 100%;
    display: inline-block;
    border-bottom: #e5e5e5 solid 1px;
    line-height: 60px;
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    height: 60px;
}
.models .model .count {
	flex-basis:100px;
	background: url(/static/img/icon1.png) no-repeat 50% 12px,url(/static/img/icon2.png) no-repeat 50%;
    padding-top: 40px;
    height: 123px;
	margin-left:30px;
}
.models .model .count .ijosf{
	font-size: 14px;
    color: #666666;
    line-height: 20px;
    text-align: center;
    width: 100%;
}
.models .model .count .pseff {
	font-size: 14px;
    color: #4994c8;
    line-height: 20px;
    text-align: center;
    width: 100%;
}
/* main tags */
.tags {
    width:290px;
	padding:20px;
    margin-bottom:8px;
}
.tags .title {
font-size: 18px;
    color: #999999;
    line-height: 2em;
}
.tags .tag {
	display: inline-block;
    text-align: center;
    padding: 0 7px;
    line-height: 22px;
    font-size: 12px;
    color: #f29b45;
    border: #f29b45 solid 1px;
    border-radius: 3px;
    margin-bottom: 8px;
    margin-right: 10px;
}
.tags .tag a {
    font-size: 12px;
    color: #f29b45;
}
.articles {
    
}
.article {
    border-bottom: #e5e5e5 solid 1px;
    padding:15px  0px;
}
.article .title {
    font-size: 16px;
    color: #333333;
    line-height: 40px;
    height: 40px;
}
.article .title a {
    color:#333;
}
.article .parameters {
    width: 100%;
    font-size: 12px;
    color: #999999;
    line-height: 35px;
}
.article .parameters span {
    margin-right:8px;
}
/* attach */
.article .attach {
    display: flex;
    font-size: 14px;
    color: #6a7a86;
    padding:12px 0px;
}
.article .attach .collect {
    padding-right:10px;
    padding-left: 20px;
    background: url(/static/img/icon7.png) no-repeat 0 50%;
    padding-right: 20px;
}
.article .attach .tag {
    padding-left: 20px;
    background: url(/static/img/icon8.png) no-repeat 0 50%;
}
.article .attach .tag a {
    margin-right:8px;
}
/* introduction */
.article .introduction {
    width: 100%;
    font-size: 14px;
    color: #666666;
    line-height: 2em;
    padding: 10px 0 15px 0;
    border-bottom: #e5e5e5 solid 1px;
}
.article .introduction .link {
    font-size: 14px;
    color: #4994c8;
    line-height: 2em;
}
.article .text {
    width: 100%;
    line-height: 2em;
    padding-top:10px;
    text-align: justify;
    color: #6e7070;
    font-size: 14px;
}
.article .text p {
    color: #333;
    font-size: 14px;
}
.article .text .pretend {
    color:#4994c8;
}
.recommends {
    margin-bottom: 15px;
    padding: 30px;
}
.recommends .title {
    font-size: 18px;
    color: #999999;
    line-height: 2em;
    width: 100%;
}
.recommends .recommend {
    width: 100%;
    font-size: 14px;
    line-height: 45px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    height: 46px;
}
/* home search */
.search {
    display:flex;
    padding-top:12px;
    padding-bottom:40px;
    justify-content: space-between;
    /* margin-bottom: 15px; */
}
.search .product {
    flex-grow: 1;
    margin-right:15px;
}
.search .content {
    flex-grow: 1;
    margin-right:15px;
}
.search .title {
    font-size: 24px;
    color: #333333;
    line-height: 45px;
}
.search .title a {
    font-size: 24px;
    color: #333333;
    line-height: 45px;
}
.search .title span {
    color:#8dbd85;
}
.search .value {
    margin-right: 15px;
    padding: 0px 8px;
    height: 42px;
    width: 300px;
}
.search button {
    flex-grow: 1;
    flex-basis: 150px;
}
/* 缩写 */
.flex-fill {
  flex: 1 1 auto !important;
}
/* flex */
.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}
/*
.d-flex
.d-inline-flex
.d-sm-flex
.d-sm-inline-flex
.d-md-flex
.d-md-inline-flex
.d-lg-flex
.d-lg-inline-flex
.d-xl-flex
.d-xl-inline-flex
*/
/* flex-direction */
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
/* flex wrap */
.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
/* justify-content */
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
/* align-items */
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}
/* align-content */
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}
/* align-self */
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
/* flex-grow */
.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}
/* order */
.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}
/* flex-shrink */
.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}
footer {
    padding:20px 0px;
    margin-top:15px;
}
footer .foot {
    padding:20px 0px;
    font-size:14px;
}
footer .foot:first-child {
    border-bottom: #e5e5e5 solid 1px;
}
footer .email {
    background: url(/static/img/icon3.png) no-repeat 0 50%;
    padding-left: 42px;
    font-size: 16px;
    color: #666666;
    line-height: 40px;
}
footer .tel {
    background: url(/static/img/icon4.png) no-repeat 0 50%;
    padding-left: 42px;
    font-size: 16px;
    color: #666666;
    line-height: 40px;
}
footer .address {
    background: url(/static/img/icon5.png) no-repeat 0 50%;
    padding-left: 42px;
    font-size: 16px;
    color: #666666;
    line-height: 40px;
}
footer .service {
    width: 170px;
    height: 40px;
    border-radius: 40px;
    text-align: center;
    background: #4994c8;
    flex-shrink: 0;
    color: #FFFFFF;
    line-height: 40px;
}
/* main tags */
._entrance {
	padding:15px;
	margin-bottom:8px;
}
.entrance {
    width: 100%;
    height: 46px;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    border-radius: 5px;
    background: #4994c8;
    color:white;
}
/* main tags */
.team {
	width:290px;
	padding:20px;
}
.team .title {
    font-size: 18px;
    color: #999999;
    line-height: 2em;
}
.team .text {
    font-size: 14px;
    color: #333333;
    line-height: 2em;
}
.team img {
	width:40%;
}
@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  /* For some Androids */ }

img {
  display: block;
  max-width: 100%; }

.img-bg-cover {
  background-size: cover;
  background-position: center; }

.img-bg-contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.abs-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.fixed-fill {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

iframe {
  width: 600px;
  height: 400px;
  margin-top: 80px; }

#container .section.data .navbar .menu a:hover {
  border-bottom-color: rgba(73, 148, 200, 0.5); }

#container .section.data .navbar .menu a.active {
  border-bottom-color: #4994c8; }

#container .section.data .status .circle {
  background-color: #4994c8; }

#container .section.data .breadcrumb {
  background-color: #4994c8; }

#container .section.data .link {
  color: #4994c8; }
  #container .section.data .link h4 {
    color: #4994c8; }

#container .section.data .template.inner .locked {
  border: solid 1px #4994c8; }

#container .section.data .icon .green {
  display: none; }

#container .section.data .close-x:before, #container .section.data .close-x:after {
  background-color: #4994c8; }

#container .section.data form input[type=submit], #container .section.data .button, #container .section.data button:not(.link) {
  background-color: #4994c8; }

#container .section.college .navbar .menu a:hover {
  border-bottom-color: rgba(73, 148, 200, 0.5); }

#container .section.college .navbar .menu a.active {
  border-bottom-color: #4994c8; }

#container .section.college .status .circle {
  background-color: #4994c8; }

#container .section.college .breadcrumb {
  background-color: #4994c8; }

#container .section.college .link {
  color: #4994c8; }
  #container .section.college .link h4 {
    color: #4994c8; }

#container .section.college .template.inner .locked {
  border: solid 1px #4994c8; }

#container .section.college .icon .green {
  display: none; }

#container .section.college .close-x:before, #container .section.college .close-x:after {
  background-color: #4994c8; }

#container .section.college form input[type=submit], #container .section.college .button, #container .section.college button:not(.link) {
  background-color: #4994c8; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, "PingFang SC", "萍方-简", "Hiragino Sans GB", 冬青黑体, STXihei, 华文细黑, "Microsoft YaHei", YaHei, 微软雅黑体, sans-serif; }

a {
  color: #4994c8;
  cursor: pointer; }

h1, h2, h3, h4, h5, h6 {
  color: #4661ad;
  margin-top: 16px; }
  h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
    margin-top: 0; }

h3 {
  font-size: 21px; }

h4 {
  color: #4661ad;
  _font-size: 16px;
  font-weight: 700; }
  h4 span {
    font-weight: 400;
    margin-left: 10px; }

.table h4 {
  font-size: 14px; }
  .table h4 span {
    margin-left: 0; }

p, pre, blockquote {
  color: #6e7070;
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
  line-height: 1.8; }

.table {
  color: #6e7070;
  font-size: 14px;
  font-weight: 400; }

.content .body p {
  word-wrap: break-word; }

blockquote {
  padding-left: 15px;
  border-left: solid 3px rgba(73, 148, 200, 0.5); }

h1 + p, h1 + pre, h2 + p, h2 + pre, h3 + p, h3 + pre, h4 + p, h4 + pre, h5 + p, h5 + pre, h6 + p, h6 + pre {
  margin-top: 16px; }

a.link {
  font-size: 14px;
  font-weight: 700;
  text-decoration: none; }

ul li, ol li {
  color: #6e7070;
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px; }

ul, ol {
  margin-top: 12px;
  margin-left: 20px; }

.content .body > div > *:first-child {
  margin-top: 0; }

.content .body p strong {
  display: block;
  margin-top: 24px; }

@media (max-width: 450px) {
  h4 {
    font-size: 15px; }
  p, pre, blockquote {
    font-size: 13px; } }

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease 300ms; }

.in-view.fade-in,
.in-view .fade-in {
  opacity: 1; }

.in-view.fade-in-up,
.in-view .fade-in-up {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.in-view-wrap.fade-in {
  opacity: 1; }

.stagger-100 {
  transition-delay: 400ms; }

.stagger-200 {
  transition-delay: 500ms; }

.stagger-300 {
  transition-delay: 600ms; }

.stagger-400 {
  transition-delay: 700ms; }

.stagger-500 {
  transition-delay: 800ms; }

.stagger-600 {
  transition-delay: 900ms; }

.stagger-700 {
  transition-delay: 1000ms; }

.stagger-800 {
  transition-delay: 1100ms; }

.stagger-900 {
  transition-delay: 1200ms; }

.stagger-1000 {
  transition-delay: 1300ms; }

.stagger-1100 {
  transition-delay: 1400ms; }

.stagger-1200 {
  transition-delay: 1500ms; }

.stagger-1300 {
  transition-delay: 1600ms; }

.stagger-1400 {
  transition-delay: 1700ms; }

.stagger-1500 {
  transition-delay: 1800ms; }

.stagger-1600 {
  transition-delay: 1900ms; }

.stagger-1700 {
  transition-delay: 2000ms; }

.stagger-1800 {
  transition-delay: 2100ms; }

.stagger-1900 {
  transition-delay: 2200ms; }

.stagger-2000 {
  transition-delay: 2300ms; }

.breadcrumb {
  color: white;
  font-weight: 700;
  font-size: 16px;
  padding: 15px 18px; }
  .breadcrumb span {
    margin: 0 10px; }
  .breadcrumb a {
    color: white;
    text-decoration: none; }
  @media (max-width: 1100px) {
    .breadcrumb {
      font-size: 14px;
      padding: 13px; } }
  @media (max-width: 600px) {
    .breadcrumb {
      padding: 13px 5.5vw; } }

.template.inner .content {
  background-color: white;
  padding: 60px; }
  @media (max-width: 1100px) {
    .template.inner .content {
      padding: 40px 7vw; } }
  @media (max-width: 600px) {
    .template.inner .content {
      padding: 40px 5.5vw; } }
  .template.inner .content .top {
    padding-bottom: 30px; }
  .template.inner .content .main.videos.false {
    flex-direction: column; }
  .template.inner .content .main {
    border-top: solid 1px rgba(110, 112, 112, 0.5);
    padding-top: 30px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between; }
    .template.inner .content .main > .text {
      padding-right: 60px;
      max-width: 560px;
      min-width: 350px;
      flex-shrink: 1; }
      .template.inner .content .main > .text .body > * {
        max-width: 100%; }
      .template.inner .content .main > .text .body > *:first-child {
        margin-top: 0; }
      .template.inner .content .main > .text .body video {
        max-width: 100%;
        height: auto; }
      .template.inner .content .main > .text .body video, .template.inner .content .main > .text .body audio, .template.inner .content .main > .text .body img {
        margin-top: 20px;
        outline: none; }
      .template.inner .content .main > .text .submit-wrap {
        position: relative;
        margin-top: 30px;
        display: flex;
        align-items: center; }
        .template.inner .content .main > .text .submit-wrap .button {
          display: flex;
          align-items: center;
          background-color: #4994c8;
          padding: 8px 20px 8px 15px;
          border-radius: 3px;
          transition: background-color 0.15s; }
          .template.inner .content .main > .text .submit-wrap .button:hover {
            background-color: rgba(73, 148, 200, 0.8) !important; }
          .template.inner .content .main > .text .submit-wrap .button:disabled {
            background-color: rgba(73, 148, 200, 0.8) !important;
            opacity: .8 !important; }
          .template.inner .content .main > .text .submit-wrap .button .icon {
            width: 25px;
            margin-right: 8px; }
          .template.inner .content .main > .text .submit-wrap .button p {
            margin-top: 0;
            font-size: 14px;
            font-weight: 800;
            color: white; }
      .template.inner .content .main > .text .protected-content .locked {
        padding: 16px;
        display: inline-block;
        background-color: rgba(73, 148, 200, 0.15); }
        .template.inner .content .main > .text .protected-content .locked > .inner {
          display: flex; }
          .template.inner .content .main > .text .protected-content .locked > .inner .icon {
            width: 19px;
            flex-shrink: 0;
            margin-right: 8px;
            margin-top: -2px; }
          .template.inner .content .main > .text .protected-content .locked > .inner .text > *:first-of-type {
            margin-top: 0; }
          .template.inner .content .main > .text .protected-content .locked > .inner .text p + p {
            margin-top: 8px; }
          .template.inner .content .main > .text .protected-content .locked > .inner .text .link {
            margin-top: 8px; }
      .template.inner .content .main > .text .body + .protected-content {
        margin-top: 40px; }
    .template.inner .content .main .image, .template.inner .content .main .video, .template.inner .content .main .blog-links {
      width: 300px;
      flex-shrink: 0;
      flex-grow: 1;
      max-width: 450px; }
    .template.inner .content .main .video video {
      max-width: 100%;
      outline: none; }
    @media (max-width: 1100px) {
      .template.inner .content .main .text {
        max-width: none;
        min-width: 0;
        padding-right: 0; }
      .template.inner .content .main .image, .template.inner .content .main .video, .template.inner .content .main .blog-links {
        float: right;
        width: 50%;
        min-width: 280px;
        max-width: none;
        padding: 0 0 40px 40px; } }
    @media (max-width: 768px) {
      .template.inner .content .main {
        display: flex;
        flex-direction: column; }
        .template.inner .content .main .image, .template.inner .content .main .video, .template.inner .content .main .blog-links {
          float: none;
          width: 100%;
          min-width: 0;
          padding-left: 0; } }

.template.inner.inner-vodka-space .content .main .text, .template.inner.inner-research-institute .content .main .text {
  max-width: 660px; }

@media (max-width: 1100px) {
  .template.inner.inner-vodka-space .content .main .blog-links, .template.inner.inner-research-institute .content .main .blog-links {
    width: auto; } }

@media (max-width: 768px) {
  .template.inner.inner-vodka-space .content .main, .template.inner.inner-research-institute .content .main {
    flex-direction: column-reverse; }
    .template.inner.inner-vodka-space .content .main .blog-links, .template.inner.inner-research-institute .content .main .blog-links {
      margin-top: 40px;
      padding-top: 20px;
      padding-bottom: 0;
      border-top: solid 1px rgba(110, 112, 112, 0.5); } }

iframe.product-doc {
  width: 100%;
  height: 600px;
  margin-top: 0;
  border: 0; }

@media screen and (max-width: 720px) {
  .template.inner .content .main > .text .protected-content .locked {
    display: block; } }

#container {
  _margin-left: 200px;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding-top: 54px; }
  #container > .section {
    position: relative; }
    #container > .section .navbar {
      position: fixed;
      z-index: 1;
      top: 54px;
      width: 100%;
      background-color: white;
      padding: 0 7vw; }
      @media (max-width: 1100px) {
        #container > .section .navbar {
          padding: 0; } }
      #container > .section .navbar > .inner {
        max-width: 1100px;
        margin: auto; }
        #container > .section .navbar > .inner .menu a {
          display: flex; }
          #container > .section .navbar > .inner .menu a .icon {
            width: 16px;
            margin-right: 7px;
            margin-top: -1px; }
    #container > .section > .main {
      padding: 120px 7vw 90px;
      _min-height: 100vh; }
      #container > .section > .main .template {
        max-width: 1100px;
        margin: auto; }
      @media (max-width: 1100px) {
        #container > .section > .main {
          padding: 145px 7vw 70px; } }

#main-header {
  padding: 0 7vw; }
  #main-header .et_menu_container {
    max-width: 1100px;
    margin: auto; }

.template.grid .items, .template.cards .items, .template.landing .items {
  display: grid;
  grid-gap: 50px 20px;
  margin-top: 50px; }
  @media (max-width: 1100px) {
    .template.grid .items, .template.cards .items, .template.landing .items {
      grid-gap: 30px 20px;
      margin-top: 30px; } }
  .template.grid .items .item, .template.cards .items .item, .template.landing .items .item {
    background-color: white; }
    .template.grid .items .item .text, .template.cards .items .item .text, .template.landing .items .item .text {
      padding: 22px 28px 18px; }
      @media (max-width: 600px) {
        .template.grid .items .item .text, .template.cards .items .item .text, .template.landing .items .item .text {
          padding: 18px 18px 16px; } }
      .template.grid .items .item .text .headline, .template.cards .items .item .text .headline, .template.landing .items .item .text .headline {
        display: flex; }
        .template.grid .items .item .text .headline .icon, .template.cards .items .item .text .headline .icon, .template.landing .items .item .text .headline .icon {
          width: 18px;
          margin-right: 8px;
          margin-top: -1px;
          flex-shrink: 0; }
      .template.grid .items .item .text .details .divider, .template.cards .items .item .text .details .divider, .template.landing .items .item .text .details .divider {
        margin: 0 5px; }
      .template.grid .items .item .text .details .showDate, .template.cards .items .item .text .details .showDate, .template.landing .items .item .text .details .showDate {
        opacity: .6;
        font-size: 12px;
        white-space: nowrap; }
      .template.grid .items .item .text .link, .template.cards .items .item .text .link, .template.landing .items .item .text .link {
        display: block;
        text-decoration: none; }
        .template.grid .items .item .text .link.more, .template.cards .items .item .text .link.more, .template.landing .items .item .text .link.more {
          margin-top: 35px;
          text-align: right; }

.template.grid .items, .template.cards .items {
  grid-template-columns: repeat(3, 1fr); }
  @media (max-width: 450px) {
    .template.grid .items, .template.cards .items {
      grid-template-columns: repeat(1, 1fr); } }
  .template.grid .items .item .image, .template.cards .items .item .image {
    background-color: rgba(73, 148, 200, 0.25); }
    .template.grid .items .item .image img, .template.cards .items .item .image img {
      background-color: white; }
  .template.grid .items .item .text, .template.cards .items .item .text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

@media (max-width: 1100px) {
  .template.grid .items {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 450px) {
  .template.grid .items {
    grid-template-columns: repeat(1, 1fr); } }

.template.grid .items .item {
  display: flex;
  flex-direction: column; }

.template.cards .items {
  grid-template-columns: repeat(2, 1fr); }
  .template.cards .items .item {
    display: flex; }
    .template.cards .items .item .image {
      width: 45%; }
    .template.cards .items .item .text {
      width: 55%; }
      .template.cards .items .item .text .contents p {
        margin-top: 9px;
        line-height: 1.5; }
      .template.cards .items .item .text .link.more {
        margin-top: 10px; }
  @media (max-width: 1100px) {
    .template.cards .items {
      grid-template-columns: repeat(1, 1fr); }
      .template.cards .items .item .image {
        width: 180px;
        flex-shrink: 0; }
      .template.cards .items .item .text {
        width: auto; } }
  @media (max-width: 600px) {
    .template.cards .items .item .image {
      width: 45%; }
    .template.cards .items .item .text {
      width: 55%; } }
  @media (max-width: 450px) {
    .template.cards .items .item {
      display: block; }
      .template.cards .items .item .image {
        width: 100%; }
      .template.cards .items .item .text {
        width: 100%; }
        .template.cards .items .item .text .link.more {
          margin-top: 35px; } }

.template.landing .items {
  grid-template-columns: repeat(2, 1fr); }
  .template.landing .items .item {
    text-align: center;
    display: flex;
    justify-content: center; }
    .template.landing .items .item .text {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .template.landing .items .item .text h4 {
        display: inline-block;
        position: relative; }
        .template.landing .items .item .text h4 .icon {
          position: absolute;
          left: -26px;
          width: 17px; }
      .template.landing .items .item .text .link.more {
        text-align: center; }
  @media (max-width: 600px) {
    .template.landing .items {
      margin-top: 30px;
      grid-gap: 30px 20px; }
      .template.landing .items .item {
        grid-column: span 2; } }

.template.table .items {
  width: 100%;
  background-color: white; }
  .template.table .items .item {
    display: flex;
    justify-content: space-between;
    padding: 10px 18px;
    font-size: 14px; }
    .template.table .items .item .icon {
      width: 18px;
      margin-right: 8px;
      margin-top: -3px; }
    .template.table .items .item .two, .template.table .items .item .three, .template.table .items .item > .link {
      margin-left: 15px; }
    .template.table .items .item > .link {
      white-space: nowrap; }
    .template.table .items .item .spacer {
      flex-grow: 1; }
    .template.table .items .item:nth-of-type(even) {
      background-color: rgba(141, 189, 133, 0.1); }
    @media (max-width: 600px) {
      .template.table .items .item {
        display: block;
        padding: 10px 13px; }
        .template.table .items .item .two, .template.table .items .item .three, .template.table .items .item > .link {
          margin-left: 0px; }
        .template.table .items .item > .link {
          text-align: right; } }
  @media (max-width: 450px) {
    .template.table .items .item .link.more {
      display: block;
      margin-top: 15px; } }

.is-ie .template.grid .items, .is-ie .template.cards .items, .is-ie .template.landing .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .is-ie .template.grid .items .item, .is-ie .template.cards .items .item, .is-ie .template.landing .items .item {
    flex-basis: 48%;
    margin-bottom: 40px; }

.is-ie .template.grid .items .item {
  flex-basis: 31%;
  display: block; }

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  height: 100vh;
  background-image: url("/images/ui/loader.svg");
  background-size: 60px;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 1s, visibility 1s; }
  .loader.first-load {
    z-index: 11;
    opacity: 1; }
    .loader.first-load.loaded {
      opacity: 0;
      visibility: hidden; }

@font-face {
  font-family: ETmodules;
  src: url(/fonts/etmodules/modules.eot);
  src: url(/fonts/etmodules/modules.eot?#iefix) format("embedded-opentype"), url(/fonts/etmodules/modules.ttf) format("truetype"), url(/fonts/etmodules/modules.woff) format("woff"), url(/fonts/etmodules/modules.svg#ETmodules) format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

nav ul,
nav ol,
nav ul li,
nav ol li {
  margin: 0;
  font-weight: inherit; }

input.et-search-field {
  display: none; }

#et-secondary-nav .menu-item-has-children > a:first-child,
#top-menu .menu-item-has-children > a:first-child {
  padding-right: 20px;
  padding-bottom: 20px; }

#main-header {
  position: fixed; }

#main-header {
  _z-index: 99999;
  z-index: 10;
  top: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  font-weight: 500;
  line-height: 23px; }

#main-header {
  transition: background-color 0.4s, color 0.4s, opacity 0.4s ease-in-out, -webkit-transform 0.4s;
  transition: background-color 0.4s, color 0.4s, transform 0.4s, opacity 0.4s ease-in-out;
  transition: background-color 0.4s, color 0.4s, transform 0.4s, opacity 0.4s ease-in-out, -webkit-transform 0.4s; }

article,
aside,
footer,
header,
hgroup,
nav,
section {
  display: block; }

.container.et_menu_container {
  z-index: 99;
  height: 54px; }

.container {
  position: relative;
  text-align: left; }

.container {
  position: relative;
  margin: auto; }

.logo_container {
  position: absolute;
  width: 100%;
  height: 100%; }

.logo_container {
  transition: all 0.4s ease-in-out; }

span.logo_helper {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle; }

a {
  color: #4994c8;
  text-decoration: none; }

#logo {
  max-height: 45%; }

#logo {
  display: inline-block;
  float: none;
  margin-bottom: 0;
  vertical-align: middle;
  transition: all 0.4s ease-in-out; }

#et-top-navigation {
  float: right;
  font-weight: 600;
  padding-top: 20px; }

#top-menu,
.fullwidth-menu,
nav#top-menu-nav,
nav.fullwidth-menu-nav {
  float: left; }

#top-menu,
#top-menu-nav {
  line-height: 0; }

#top-menu li {
  display: inline-block;
  padding-right: 22px;
  font-size: 14px; }

#et-secondary-menu li,
#top-menu li {
  word-wrap: break-word; }

.nav li {
  position: relative;
  line-height: 1em; }

@media (max-width: 981px) {
  nav > ul > li > a {
    padding-bottom: 20px; } }

#et-secondary-nav .menu-item-has-children > a:first-child:after,
#top-menu .menu-item-has-children > a:first-child:after {
  position: absolute;
  top: 0;
  right: 0;
  font-family: ETmodules;
  font-size: 16px;
  font-weight: 800;
  content: "3"; }

.nav li ul {
  text-align: left; }

.nav li ul {
  visibility: hidden;
  z-index: 9999;
  width: 240px;
  border-top: 3px solid #4994c8;
  opacity: 0;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); }

.nav li ul {
  position: absolute;
  padding: 20px 0; }

.et-search-form,
.et_mobile_menu,
.footer-widget li:before,
.nav li ul,
blockquote {
  border-color: #4994c8; }

#top-menu li li {
  margin: 0;
  padding: 0 20px; }

#top-menu li {
  display: inline-block;
  padding-right: 22px;
  font-size: 14px; }

.nav li li {
  position: relative;
  line-height: 2em; }

.nav li li {
  margin: 0;
  padding: 0 20px; }

.nav li {
  position: relative;
  line-height: 1em; }

#top-menu li li a {
  width: 200px;
  padding: 6px 20px; }

#top-menu a {
  display: block;
  position: relative;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  transition: all 0.4s ease-in-out; }

#top-menu > li:last-child {
  padding-right: 0px; }

#et-info-email:hover,
#et-secondary-menu > ul > li > a:hover,
#top-menu-nav > ul > li > a:hover,
.et-social-icons a:hover {
  opacity: 0.7;
  transition: all 0.4s ease-in-out; }

#top-menu a.active {
  color: #4994c8; }

#top-menu {
  font-family: "Open Sans", Arial, sans-serif; }

.nav ul li a:hover {
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.03); }

#et_mobile_nav_menu {
  display: none; }

@media (max-width: 981px) {
  #top-menu {
    display: none; }
  #et_mobile_nav_menu {
    display: block;
    margin-top: 3px;
    font-weight: 600; }
  .mobile_menu_bar.mobile_menu_bar_toggle {
    top: -8px; }
  .mobile_nav ul,
  .mobile_nav ol {
    list-style: none;
    margin: 0; }
    .mobile_nav ul li,
    .mobile_nav ol li {
      margin: 0;
      font-weight: 600; }
  .mobile_nav .select_page {
    display: none; }
  .mobile_menu_bar:before {
    color: #4994c8; }
  .mobile_menu_bar {
    display: block;
    position: relative;
    line-height: 0; }
  .mobile_menu_bar:before {
    position: relative;
    top: 0;
    left: 0;
    font-size: 32px;
    content: "\61";
    cursor: pointer; }
  .et_mobile_menu {
    visibility: visible;
    position: absolute;
    z-index: 9999;
    left: 0;
    width: 100%;
    padding: 5%;
    border-top: 3px solid #4994c8;
    opacity: 1;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 0; }
  .et_mobile_menu .menu-item-has-children > a {
    background-color: rgba(0, 0, 0, 0.03);
    font-weight: 700; }
  .et_mobile_menu li a {
    display: block;
    padding: 10px 5%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    color: #666; }
  .et_mobile_menu li a,
  .nav li li a {
    font-size: 14px;
    transition: all 0.2s ease-in-out; }
  #main-header .et_mobile_menu li ul,
  .et_pb_fullwidth_menu .et_mobile_menu li ul {
    display: block !important;
    visibility: visible !important;
    padding-left: 10px; }
  .et_mobile_menu li li {
    padding-left: 5%; }
  .et_mobile_menu li a:hover {
    opacity: 0.7;
    background-color: rgba(0, 0, 0, 0.03); }
  #mobile_menu {
    overflow-y: scroll;
    max-height: calc(100vh - 54px);
    padding-bottom: 130px; }
  #mobile_menu,
  #main-header .et_mobile_menu li ul,
  .et_pb_fullwidth_menu .et_mobile_menu li ul {
    opacity: 0;
    visibility: hidden !important;
    transition: opacity 0.3s, visibility 0.3s; }
  body.navIn #mobile_menu,
  body.navIn #main-header .et_mobile_menu li ul,
  body.navIn .et_pb_fullwidth_menu .et_mobile_menu li ul {
    opacity: 1;
    visibility: visible !important; } }

et-info-email:before,
#et-info-phone:before,
#et_search_icon:before,
.comment-reply-link:after,
.et-cart-info span:before,
.et-pb-arrow-next:before,
.et-pb-arrow-prev:before,
.et-social-icon a:before,
.et_audio_container .mejs-playpause-button button:before,
.et_audio_container .mejs-volume-button button:before,
.et_overlay:before,
.et_password_protected_form .et_submit_button:after,
.et_pb_button:after,
.et_pb_contact_reset:after,
.et_pb_contact_submit:after,
.et_pb_font_icon:before,
.et_pb_newsletter_button:after,
.et_pb_pricing_table_button:after,
.et_pb_promo_button:after,
.et_pb_social_icon a.icon:before,
.et_pb_testimonial:before,
.et_pb_toggle_title:before,
.form-submit .et_pb_button:after,
.mobile_menu_bar:before,
.woocommerce #content input.button.alt:after,
.woocommerce #content input.button:after,
.woocommerce #respond input#submit.alt:after,
.woocommerce #respond input#submit:after,
.woocommerce a.button.alt:after,
.woocommerce button.button.alt:after,
.woocommerce button.button:after,
.woocommerce button.single_add_to_cart_button.button:after,
.woocommerce input.button.alt:after,
.woocommerce input.button:after,
.woocommerce-page #content input.button.alt:after,
.woocommerce-page #content input.button:after,
.woocommerce-page #respond input#submit.alt:after,
.woocommerce-page #respond input#submit:after,
.woocommerce-page a.button.alt:after,
.woocommerce-page a.button:after,
.woocommerce-page button.button.alt:after,
.woocommerce-page button.button:after,
.woocommerce-page input.button.alt:after,
.woocommerce-page input.button:after,
a.et_pb_more_button:after {
  text-shadow: 0 0;
  font-family: ETmodules !important;
  font-weight: 400;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  text-transform: none;
  speak: none; }

.nav li li ul {
  z-index: 1000;
  top: -23px;
  left: 240px; }

.nav li.et-touch-hover > ul,
.nav li:hover > ul {
  visibility: visible;
  opacity: 1; }

.nav li ul {
  transition: all 0.2s; }

#top-menu > li > ul > li > a {
  padding-bottom: 6px !important; }

#top-menu > li > ul > li > a::after {
  top: 6px !important; }

.et_mobile_menu li .menu-item-has-children > a {
  background-color: transparent; }

#container > .section .navbar {
  border-bottom: solid 1px #f0f0f0; }
  #container > .section .navbar .inner {
    background-color: white;
    display: flex;
    justify-content: space-between; }
    #container > .section .navbar .inner .menu {
      display: flex; }
      #container > .section .navbar .inner .menu a {
        color: #4994c8;
        font-weight: 700;
        font-size: 14px;
        padding: 17px 30px 12px;
        text-decoration: none;
        border-bottom: solid 5px transparent;
        transition: border-color .3s; }

#container > .section .status {
  font-size: 14px;
  color: #6e7070;
  padding: 16px 0px 13px 30px; }
  #container > .section .status button {
    margin-left: 8px; }
  #container > .section .status .circle {
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: grey;
    margin-right: 8px; }
  #container > .section .status.mobile {
    display: none; }

@media (max-width: 1100px) {
  #container > .section .navbar {
    padding-top: 0;
    background-color: #333333;
    _transition: padding .5s; }
    #container > .section .navbar .inner {
      position: relative;
      display: block; }
      #container > .section .navbar .inner .menu a {
        justify-content: center;
        width: 25%;
        font-size: 13px;
        text-align: center;
        padding: 13px 0 10px;
        border-left: solid 1px #f0f0f0; } }
      @media (max-width: 1100px) and (max-width: 450px) {
        #container > .section .navbar .inner .menu a .icon {
          width: 12px;
          margin-right: 4px;
          margin-top: 1px; } }

@media (max-width: 1100px) {
        #container > .section .navbar .inner .menu a:first-of-type {
          border-left: 0; }
        #container > .section .navbar .inner .menu a.active {
          border-bottom-width: 4px; } }
    @media (max-width: 1100px) and (max-width: 370px) {
      #container > .section .navbar .inner .menu a {
        font-size: 12px; }
        #container > .section .navbar .inner .menu a .icon {
          margin-right: 1px; } }

@media (max-width: 1100px) {
  #container > .section .status.desktop {
    display: none; }
  #container > .section .status.mobile {
    display: block;
    position: absolute;
    top: 46px;
    right: 0;
    padding-right: 34px;
    font-size: 12px; }
    #container > .section .status.mobile .link {
      font-size: 12px; }
  #mobileThumb {
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s; }
  body.menu-up #container > .section .navbar {
    padding-top: 0; }
  body.menu-up #mobileThumb {
    -webkit-transform: translateY(-43px);
            transform: translateY(-43px); } }

#login-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11; }
  #login-overlay .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); }
  #login-overlay .scroll-wrap {
    overflow-y: auto;
    display: flex;
    height: 100%;
    padding: 7vw; }
    #login-overlay .scroll-wrap .center-wrap {
      margin-top: auto;
      margin-bottom: auto;
      width: 100%; }
      #login-overlay .scroll-wrap .center-wrap .content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
        #login-overlay .scroll-wrap .center-wrap .content .form-holder {
          position: relative;
          z-index: 1;
          width: 380px;
          padding: 40px;
          background-color: white;
          padding: 35px; }
          #login-overlay .scroll-wrap .center-wrap .content .form-holder form {
            margin-top: 25px; }
          #login-overlay .scroll-wrap .center-wrap .content .form-holder.login {
            width: 760px;
            max-width: 100%;
            display: flex; }
            #login-overlay .scroll-wrap .center-wrap .content .form-holder.login > div {
              width: 50%;
              flex-grow: 0;
              flex-shrink: 0; }
            #login-overlay .scroll-wrap .center-wrap .content .form-holder.login .new-account {
              padding-right: 50px; }
              #login-overlay .scroll-wrap .center-wrap .content .form-holder.login .new-account h4 + p {
                margin-top: 22px; }
            #login-overlay .scroll-wrap .center-wrap .content .form-holder.login .log-in {
              padding-left: 50px;
              border-left: solid 1px rgba(110, 112, 112, 0.5); }
            @media (max-width: 1100px) {
              #login-overlay .scroll-wrap .center-wrap .content .form-holder.login {
                width: 380px;
                flex-direction: column-reverse; }
                #login-overlay .scroll-wrap .center-wrap .content .form-holder.login > div {
                  width: 100%; }
                #login-overlay .scroll-wrap .center-wrap .content .form-holder.login .log-in {
                  padding-bottom: 30px;
                  padding-left: 0;
                  border-left: none;
                  border-bottom: solid 1px rgba(110, 112, 112, 0.5); }
                #login-overlay .scroll-wrap .center-wrap .content .form-holder.login .new-account {
                  padding-top: 30px;
                  padding-right: 0; } }

@media (max-width: 768px) {
  #login-overlay .scroll-wrap {
    padding-left: 7vw; }
    #login-overlay .scroll-wrap .center-wrap .content .form-holder {
      padding: 30px; } }

form input, form textarea, form button {
  -webkit-appearance: none;
  border-radius: 0; }

form input *:first-of-type {
  margin-top: 0; }

form input[type=text], form input[type=email], form input[type=password], form input[type=textarea] {
  display: block;
  width: 100%;
  margin-top: 18px;
  border: solid 1px grey;
  font-size: 16px;
  line-height: 1.5;
  padding: 10px 10px; }

form input:disabled {
  cursor: not-allowed !important;
  opacity: .4; }

form .note {
  padding: 15px;
  margin-top: 18px;
  background-color: #f0f0f0; }
  form .note p {
    font-size: 14px;
    line-height: 1.5;
    color: #4661ad;
    margin-top: 0; }

form input[type=submit] {
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: grey;
  font-size: 15px;
  font-weight: 700;
  color: white;
  padding: 10px 15px;
  line-height: 1.5; }
  form input[type=submit].link {
    background-color: transparent;
    display: inline;
    padding: 0;
    text-decoration: underline;
    color: #4661ad;
    font-weight: 700; }
    form input[type=submit].link.smaller {
      font-size: 14px;
      font-weight: 400;
      text-decoration: none; }

.fieldRow {
  display: flex;
  justify-content: space-between; }
  .fieldRow:first-child input {
    margin-top: 0; }
  .fieldRow input {
    width: calc(50% - 10px) !important; }

.input-wrap {
  position: relative; }
  .input-wrap .button, .input-wrap button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%; }

.button:disabled, button:disabled {
  cursor: not-allowed !important;
  opacity: .4;
  transition: opacity .15s; }

.thankyou {
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s;
  _transition-duration: 0s;
  font-size: .85em;
  margin: 0;
  margin-top: .5em; }
  .thankyou.show {
    opacity: 1;
    visibility: visible;
    transition-duration: .3s; }

.button, button, form input[type=submit] {
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: grey;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  padding: 10px 15px;
  line-height: 1.5; }

.button.link, button.link, form input[type=submit].link {
  background-color: transparent;
  display: inline;
  padding: 0;
  text-decoration: underline;
  color: #4661ad;
  font-weight: 700;
  transition: opacity .3s; }

button.link:disabled {
  opacity: .5;
  cursor: default; }

::-webkit-input-placeholder {
  color: grey; }

:-ms-input-placeholder {
  color: grey; }

::placeholder {
  color: grey; }

.submit-wrap {
  position: relative;
  text-align: right;
  clear: both;
  margin-top: 25px; }
  .submit-wrap .loader {
    position: relative;
    display: inline-block;
    width: 1.8em;
    height: 1.8em;
    margin-right: 1em;
    margin-left: 1em;
    vertical-align: middle;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s; }
    .submit-wrap .loader div {
      width: 100%;
      height: 100%; }
      .submit-wrap .loader div div {
        width: 100%;
        height: 100%; }
        .submit-wrap .loader div div svg {
          width: 100%;
          height: 100%; }
  .submit-wrap.loading .loader {
    opacity: .7;
    visibility: visible; }
  .submit-wrap.full {
    margin-top: 1em;
    position: relative; }
    .submit-wrap.full .loader {
      position: absolute;
      right: 1em;
      top: 0;
      bottom: 0;
      margin: auto; }
  .submit-wrap input {
    display: inline-block !important;
    float: none !important;
    margin: 0 !important;
    vertical-align: middle; }
    .submit-wrap input:active {
      top: 0 !important; }
  .submit-wrap.footer {
    position: relative;
    float: right; }
    .submit-wrap.footer .loader {
      position: absolute;
      top: 0;
      right: 5px;
      width: 1.2em;
      pointer-events: none; }
    .submit-wrap.footer input {
      position: absolute;
      top: 0;
      right: 0; }

.close-x {
  display: block;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
  width: 12px;
  height: 12px;
  opacity: 1;
  transition: opacity .3s; }
  .close-x:hover {
    _opacity: .7; }
  .close-x:before, .close-x:after {
    position: absolute;
    left: 5px;
    content: ' ';
    height: 12px;
    width: 2px;
    background-color: grey; }
  .close-x:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .close-x:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }

/* user */
#main-header {
  opacity: 1; }

.oops {
  min-height: calc(100vh - 55px);
  display: flex;
  justify-content: center;
  align-items: center; }
  .oops h1 {
    font-size: 32px;
    color: #4460AC;
    padding-bottom: 16vh;
    width: 760px;
    text-align: center; }
    .oops h1 p {
      margin-top: -30px;
      font-size: 22px; }
    .oops h1 ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 14px;
      text-align: center; }
      .oops h1 ul li {
        display: inline-block;
        width: 260px;
        height: 65px;
        text-align: center;
        margin: 40px; }
        .oops h1 ul li a {
          width: 260px;
          height: 65px;
          line-height: 65px;
          text-align: center;
          display: block;
          font-weight: 700;
          font-size: 26px;
          color: #4460ac;
          background: #fff;
          box-shadow: 0px 0px 10px #878787; }
          .oops h1 ul li a.t {
            background: #4460ac;
            color: #fff;
            font-weight: 400; }

@media screen and (max-width: 720px) {
  .oops h1 img {
    width: 70%;
    display: block;
    margin: 0 auto; }
  .oops h1 p {
    margin-top: 0px;
    padding: 0 20px;
    padding-bottom: 30px;
    font-size: 16px; }
  .oops h1 ul {
    padding: 0 20px; }
    .oops h1 ul li {
      width: 60%;
      height: auto;
      margin: 0 0 20px 0;
      line-height: normal;
      line-height: initial; }
      .oops h1 ul li a {
        display: block;
        width: 100%;
        height: auto;
        line-height: normal;
        line-height: initial;
        font-size: 16px;
        padding: 16px 0; } }

.main.user {
  opacity: 1;
  min-height: calc(100vh - 55px);
  background: url("/static/img/use_bg.svg") no-repeat center bottom;
  background-size: cover; }
  .main.user .loading {
    display: none !important; }
  .main.user .oops h1 {
    color: #fff; }
  .main.user h4 {
    margin: 0;
    font-weight: 700;
    font-style: normal;
    font-size: 40px;
    color: #FFFFFF;
    position: relative; }
    .main.user h4.header {
      padding-top: 3%; }
    .main.user h4 a {
      position: absolute;
      right: 0;
      bottom: 6px;
      font-weight: 350;
      font-style: normal;
      font-size: 16px;
      letter-spacing: 1px;
      color: #FFFF00; }
  .main.user .new-account {
    zoom: 0.9;
    min-height: calc(100vh - 55px);
    display: flex;
    justify-content: center;
    align-items: center; }
    .main.user .new-account .warp {
      width: 1080px;
      margin-bottom: 10vh; }
  .main.user form {
    margin-top: 10px;
    background: #fff;
    border-radius: 10px; }
    .main.user form .stepBar {
      padding: 20px;
      padding-bottom: 40px;
      background: #7e8ec6;
      border-radius: 10px; }
      .main.user form .stepBar[data-step=step1] ul li:nth-child(1) {
        color: #4460AC;
        background: #fff; }
        .main.user form .stepBar[data-step=step1] ul li:nth-child(1) span {
          display: block; }
      .main.user form .stepBar[data-step=step2] ul li:nth-child(2) {
        color: #4460AC;
        background: #fff; }
        .main.user form .stepBar[data-step=step2] ul li:nth-child(2) span {
          display: block; }
      .main.user form .stepBar[data-step=step3] ul li:nth-child(3) {
        color: #4460AC;
        background: #fff; }
        .main.user form .stepBar[data-step=step3] ul li:nth-child(3) span {
          display: block; }
      .main.user form .stepBar ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto;
        width: 473px;
        height: 73px;
        list-style: none;
        background: url("/static/img/use_stepbar_bg.svg") no-repeat 0 0; }
        .main.user form .stepBar ul li {
          position: relative;
          margin: 0;
          width: 45px;
          border-radius: 50%;
          margin: 14px;
          text-align: center;
          line-height: 43px;
          color: #fff;
          font-size: 20px;
          font-weight: 700; }
          .main.user form .stepBar ul li span {
            display: none;
            position: absolute;
            left: -26px;
            bottom: -50px;
            font-weight: 350;
            font-style: normal;
            font-size: 15px;
            color: #FFFFFF;
            width: 100px;
            text-align: center; }
          .main.user form .stepBar ul li.active {
            color: #4460AC;
            background: #fff; }
            .main.user form .stepBar ul li.active span {
              display: block; }
    .main.user form .stepBox {
      padding: 80px 0 50px 0; }
      .main.user form .stepBox.success {
        padding: 30px 0; }
        .main.user form .stepBox.success h3 {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 10px; }
        .main.user form .stepBox.success h4 {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center; }
          .main.user form .stepBox.success h4 p {
            margin: 0;
            position: absolute;
            left: 62%;
            color: #4661ad; }
        .main.user form .stepBox.success ul {
          margin: 0;
          padding: 20px 0;
          list-style: none;
          display: flex;
          justify-content: center;
          align-items: center; }
          .main.user form .stepBox.success ul li {
            padding: 0 60px; }
            .main.user form .stepBox.success ul li a {
              display: block;
              width: 216px;
              text-align: center;
              padding: 6px 0;
              background-color: #4460ac;
              border-radius: 30px;
              font-size: 23px;
              color: #fff; }
    .main.user form dl {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 30px; }
      .main.user form dl.t {
        padding-bottom: 16px; }
        .main.user form dl.t dd {
          position: relative; }
          .main.user form dl.t dd button {
            position: absolute;
            right: 0;
            top: 0;
            background: #4460ac;
            padding: 9px 20px;
            font-size: 18px; }
            .main.user form dl.t dd button[disabled] {
              background: #d7d7d7;
              color: #555; }
      .main.user form dl dt {
        font-weight: 500;
        font-style: normal;
        font-size: 23px;
        text-align: right;
        width: 150px;
        padding-right: 10px; }
        .main.user form dl dt span {
          color: #D9001B; }
      .main.user form dl dd {
        position: relative;
        width: 477px;
        margin-right: 50px; }
        .main.user form dl dd .error {
          position: absolute;
          left: 190px;
          top: 20px;
          font-size: 12px;
          color: #D9001B;
          padding-left: 20px; }
        .main.user form dl dd input {
          margin: 0;
          width: 477px;
          height: 46px;
          padding: 3px 2px 3px 10px;
          font-family: 'Arial Normal', 'Arial';
          font-weight: 400;
          font-style: normal;
          font-size: 16px;
          letter-spacing: normal;
          color: #AAAAAA;
          vertical-align: none;
          text-align: left;
          text-transform: none;
          border: 1px solid #000; }
          .main.user form dl dd input[type=submit] {
            width: auto;
            height: auto;
            border: none;
            background: #4460ac;
            color: #fff;
            padding: 8px 70px;
            font-size: 18px; }
        .main.user form dl dd button {
          background: #4460ac;
          padding: 8px 70px;
          font-size: 18px; }
        .main.user form dl dd .error-holder p {
          margin: 0;
          padding-bottom: 16px;
          color: rgba(217, 0, 27, 0.996078); }
          .main.user form dl dd .error-holder p.success {
            display: block;
            color: #333; }
          .main.user form dl dd .error-holder p.link {
            display: block;
            color: #333; }
    .main.user form .notice {
      margin: 0;
      text-align: center;
      font-size: 12px;
      padding-bottom: 20px; }
  .main.user .login {
    padding-top: 180px;
    min-height: calc(100vh - 55px);
    background: url("/static/img/use_bg2.svg") no-repeat center bottom;
    justify-content: center;
    align-items: center; }
    .main.user .login .success {
      display: none; }
    .main.user .login[data-success=success] {
      background: none; }
      .main.user .login[data-success=success] .use_login_body,
      .main.user .login[data-success=success] .use_login_hand {
        display: none; }
      .main.user .login[data-success=success] .log-in {
        display: none; }
      .main.user .login[data-success=success] .success {
        display: block;
        width: 450px;
        margin: 0 auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        padding: 50px 0 30px 0;
        margin-bottom: 55px;
        position: relative; }
        .main.user .login[data-success=success] .success h3 {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 10px;
          position: relative;
          z-index: 1; }
        .main.user .login[data-success=success] .success .use_login_half {
          position: absolute;
          right: -99px;
          top: 0;
          z-index: 0; }
        .main.user .login[data-success=success] .success h4 {
          text-align: center; }
          .main.user .login[data-success=success] .success h4 img {
            margin: 0 auto; }
          .main.user .login[data-success=success] .success h4 p {
            margin: 0;
            color: #4661ad; }
        .main.user .login[data-success=success] .success ul {
          margin: 0;
          padding: 20px 0 0 0;
          list-style: none;
          text-align: right; }
          .main.user .login[data-success=success] .success ul li {
            padding: 0 20px; }
            .main.user .login[data-success=success] .success ul li a {
              text-align: center;
              padding: 8px 18px;
              background-color: #4460ac;
              border-radius: 5px;
              font-size: 18px;
              color: #fff; }
    .main.user .login .log-in {
      zoom: 0.9;
      width: 450px;
      margin: 0 auto;
      padding: 30px;
      padding-top: 0;
      background: #fff;
      border-radius: 10px;
      position: relative; }
      .main.user .login .log-in .error-holder p {
        color: rgba(217, 0, 27, 0.996078); }
        .main.user .login .log-in .error-holder p.success {
          display: block;
          color: #333; }
        .main.user .login .log-in .error-holder p.link {
          display: block;
          color: #333; }
      .main.user .login .log-in h4 {
        font-weight: 700;
        font-style: normal;
        font-size: 25px;
        color: #4460AC;
        padding-bottom: 16px;
        padding-top: 50px;
        position: relative;
        z-index: 2;
        background: #fff; }
        .main.user .login .log-in h4 span {
          font-weight: 700;
          font-style: normal;
          font-size: 12px;
          color: #AAAAAA; }
      .main.user .login .log-in .use_login_body {
        position: absolute;
        left: 50%;
        top: -170px;
        margin-left: -91px;
        z-index: 1; }
      .main.user .login .log-in .use_login_hand {
        position: absolute;
        left: 50%;
        top: -50px;
        margin-left: -92px;
        z-index: 3; }
      .main.user .login .log-in button[type=button] {
        background-color: #4460ac;
        font-size: 18px;
        padding-left: 30px;
        padding-right: 30px; }
      .main.user .login .log-in .submit-wrap .loader {
        display: none; }
      .main.user .login .log-in .submit-wrap input, .main.user .login .log-in .submit-wrap a {
        display: block;
        width: 100%;
        background-color: #4460ac;
        font-size: 20px;
        border-radius: 30px;
        text-align: center;
        padding: 10px 15px; }
      .main.user .login .log-in .submit-wrap a {
        margin-top: 20px;
        border: 1px solid #4460ac;
        background: #fff;
        color: #4460ac; }
  @media screen and (max-width: 720px) {
    .main.user .new-account .warp {
      margin: 0 20px;
      width: calc(100% - 40px); }
    .main.user h4 {
      font-size: 30px; }
    .main.user form .stepBox {
      padding-top: 30px; }
    .main.user form .stepBox {
      padding-top: 30px; }
    .main.user form .stepBar {
      padding: 20px 10px 30px 10px; }
    .main.user form .stepBar ul {
      zoom: 0.8; }
    .main.user form dl {
      padding-bottom: 10px; }
    .main.user form dl dt {
      width: 30vw; }
    .main.user form dl dt {
      padding-right: 0; }
    .main.user form dl dd {
      width: 60vw; }
    .main.user form dl dd input {
      width: 100%; }
    .main.user form dl dt {
      font-size: 16px; }
    .main.user form .notice {
      padding: 20px; }
    .main.user form .stepBox.success ul {
      display: block; }
    .main.user form .stepBox.success h4 {
      display: block;
      text-align: center; }
    .main.user form .stepBox.success h4 img {
      display: inline; }
    .main.user form .stepBox.success h4 p {
      position: static;
      position: initial; }
    .main.user form .stepBox.success ul li a {
      width: 100%; }
    .main.user form dl dd .error {
      position: static;
      position: initial;
      display: block;
      padding: 0;
      padding-bottom: 10px; }
    .main.user .login .log-in {
      margin: 0 20px;
      width: calc(100% - 40px); }
    .main.user .login .log-in .use_login_body {
      zoom: 0.8; }
    .main.user .login .log-in .use_login_hand {
      zoom: 0.8; }
    .main.user .login[data-success=success] .success {
      margin: 0 20px;
      width: calc(100% - 40px); }
    .main.user .login[data-success=success] .success .use_login_half {
      display: none; } }
  @media screen and (max-width: 385px) {
    .main.user form .stepBox {
      padding-top: 20px;
      padding-bottom: 20px; }
    .main.user form .stepBar ul {
      zoom: 0.7; }
    .main.user .login .log-in .use_login_body {
      zoom: 0.7; }
    .main.user .login .log-in .use_login_hand {
      zoom: 0.7; }
    .main.user form dl dd input {
      width: 110%; }
    .main.user form dl.t dd button {
      right: -10%; }
    .main.user form .notice {
      padding-top: 0; }
    .main.user form .stepBox.success {
      padding: 20px 0; }
    .main.user form .stepBox.success ul {
      padding: 0; }
    .main.user form .stepBox.success ul li a {
      font-size: 20px; } }
  @media screen and (max-width: 320px) {
    .main.user form .stepBar {
      padding: 20px 10px; }
    .main.user form .stepBar ul {
      zoom: 0.6; }
    .main.user h4 {
      font-size: 24px; }
      .main.user h4 a {
        font-size: 13px; }
    .main.user form dl dt {
      font-size: 13px; }
    .main.user form dl dd button {
      font-size: 16px;
      padding: 8px 20px; }
    .main.user form dl.t dd button {
      font-size: 14px;
      line-height: 28px; }
    .main.user form .stepBox.success ul li a {
      font-size: 16px; }
    .main.user .login .log-in .use_login_body {
      zoom: 0.6; }
    .main.user .login .log-in .use_login_hand {
      zoom: 0.6; }
    .main.user .login .log-in h4 {
      padding-top: 20px;
      padding-bottom: 0; }
    .main.user .submit-wrap {
      margin-top: 15px; }
    .main.user .login[data-success=success] .success {
      padding: 20px 0; } }

@media screen and (max-width: 320px) {
  .main.user {
    min-height: auto; }
    .main.user .new-account {
      padding-bottom: 50px; } }

/* user */
.zoom2 .top h4 {
  font-size: 20px; }

.zoom2 .top p {
  font-size: 16px; }

.zoom2 .main .body * {
  font-size: 16px; }

.zoom2 .main .image {
  width: 360px !important;
  max-width: 360px !important; }
  .zoom2 .main .image .passport {
    margin-top: 16px;
    background: rgba(73, 148, 200, 0.15);
    padding: 16px; }
    .zoom2 .main .image .passport p {
      font-size: 14px;
      margin: 0;
      padding-bottom: 1em; }
    .zoom2 .main .image .passport button {
      display: block;
      text-align: center;
      width: 100%; }

@media screen and (max-width: 720px) {
  .zoom2 .main .image {
    width: auto !important;
    max-width: none !important;
    max-width: initial !important; } }

.main.passport {
  opacity: 1;
  min-height: calc(100vh - 55px);
  background: url("/static/img/use_bg.svg") no-repeat center bottom;
  background-size: cover; }
  .main.passport .loading {
    display: none !important; }
  .main.passport .oops h1 {
    color: #fff; }
  .main.passport h4 {
    margin: 0;
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    color: #4661AD;
    position: relative; }
    .main.passport h4.header {
      padding-top: 3%; }
    .main.passport h4 a {
      position: absolute;
      right: 0;
      bottom: 6px;
      font-weight: 350;
      font-style: normal;
      font-size: 16px;
      letter-spacing: 1px;
      color: #FFFF00; }
  .main.passport .new-account {
    zoom: 1;
    min-height: calc(100vh - 55px);
    display: flex;
    justify-content: center;
    align-items: center; }
    .main.passport .new-account .left {
      padding-right: 70px; }
      .main.passport .new-account .left h2 {
        font-size: 40px;
        font-weight: 700;
        color: #fff;
        line-height: 120%; }
      .main.passport .new-account .left ul {
        margin: 0;
        padding: 2em 0;
        padding-top: 1em; }
        .main.passport .new-account .left ul li {
          padding-left: 1.4em;
          line-height: 120%;
          color: #fff;
          font-size: 20px;
          list-style: none;
          position: relative; }
          .main.passport .new-account .left ul li::before {
            position: absolute;
            left: 4px;
            top: 10px;
            content: '';
            width: 12px;
            height: 12px;
            background: #fff;
            border-radius: 50%; }
      .main.passport .new-account .left .macbook {
        left: -60px;
        position: relative;
        width: 635px;
        height: 360px;
        background: url("/static/img/passport_macbook.svg") no-repeat center top; }
        .main.passport .new-account .left .macbook .swiper-container {
          position: absolute;
          left: 80px;
          top: 19px;
          width: 472px; }
          .main.passport .new-account .left .macbook .swiper-container img {
            width: 472px;
            height: 286px; }
    .main.passport .new-account .reg {
      width: 450px; }
      .main.passport .new-account .reg form {
        padding: 40px 50px; }
    .main.passport .new-account .textR {
      text-align: right; }
      .main.passport .new-account .textR a {
        color: #4460ac; }
  .main.passport form {
    background: #fff;
    border-radius: 10px; }
    .main.passport form .stepBar {
      padding: 20px;
      padding-bottom: 40px;
      background: #7e8ec6;
      border-radius: 10px; }
      .main.passport form .stepBar[data-step=step1] ul li:nth-child(1) {
        color: #4460AC;
        background: #fff; }
        .main.passport form .stepBar[data-step=step1] ul li:nth-child(1) span {
          display: block; }
      .main.passport form .stepBar[data-step=step2] ul li:nth-child(2) {
        color: #4460AC;
        background: #fff; }
        .main.passport form .stepBar[data-step=step2] ul li:nth-child(2) span {
          display: block; }
      .main.passport form .stepBar[data-step=step3] ul li:nth-child(3) {
        color: #4460AC;
        background: #fff; }
        .main.passport form .stepBar[data-step=step3] ul li:nth-child(3) span {
          display: block; }
      .main.passport form .stepBar ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto;
        width: 473px;
        height: 73px;
        list-style: none;
        background: url("/static/img/use_stepbar_bg.svg") no-repeat 0 0; }
        .main.passport form .stepBar ul li {
          position: relative;
          margin: 0;
          width: 45px;
          border-radius: 50%;
          margin: 14px;
          text-align: center;
          line-height: 43px;
          color: #fff;
          font-size: 20px;
          font-weight: 700; }
          .main.passport form .stepBar ul li span {
            display: none;
            position: absolute;
            left: -26px;
            bottom: -50px;
            font-weight: 350;
            font-style: normal;
            font-size: 15px;
            color: #FFFFFF;
            width: 100px;
            text-align: center; }
          .main.passport form .stepBar ul li.active {
            color: #4460AC;
            background: #fff; }
            .main.passport form .stepBar ul li.active span {
              display: block; }
    .main.passport form .stepBox {
      padding: 30px 0 0px 0; }
      .main.passport form .stepBox.success {
        padding: 30px 0; }
        .main.passport form .stepBox.success h3 {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 10px; }
        .main.passport form .stepBox.success h4 {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center; }
          .main.passport form .stepBox.success h4 p {
            margin: 0;
            position: absolute;
            left: 62%;
            color: #4661ad; }
        .main.passport form .stepBox.success ul {
          margin: 0;
          padding: 20px 0;
          list-style: none;
          display: flex;
          justify-content: center;
          align-items: center; }
          .main.passport form .stepBox.success ul li {
            padding: 0 60px; }
            .main.passport form .stepBox.success ul li a {
              display: block;
              width: 216px;
              text-align: center;
              padding: 6px 0;
              background-color: #4460ac;
              border-radius: 30px;
              font-size: 23px;
              color: #fff; }
    .main.passport form dl {
      padding-bottom: 16px; }
      .main.passport form dl.t {
        padding-bottom: 16px; }
        .main.passport form dl.t dd {
          position: relative; }
          .main.passport form dl.t dd button {
            position: absolute;
            right: 0;
            top: 0;
            background: #4460ac;
            padding: 9px 20px;
            font-size: 18px; }
            .main.passport form dl.t dd button[disabled] {
              background: #d7d7d7;
              color: #555; }
      .main.passport form dl dt {
        font-weight: 500;
        font-style: normal;
        font-size: 23px;
        text-align: right;
        width: 150px;
        padding-right: 10px; }
        .main.passport form dl dt span {
          color: #D9001B; }
      .main.passport form dl dd {
        position: relative; }
        .main.passport form dl dd .error {
          position: absolute;
          left: 190px;
          top: 20px;
          font-size: 12px;
          color: #D9001B;
          padding-left: 20px; }
        .main.passport form dl dd input {
          margin: 0;
          height: 46px;
          padding: 3px 2px 3px 10px;
          font-family: 'Arial Normal', 'Arial';
          font-weight: 400;
          font-style: normal;
          font-size: 16px;
          letter-spacing: normal;
          vertical-align: none;
          text-align: left;
          text-transform: none;
          border: 1px solid #000; }
          .main.passport form dl dd input[type=submit] {
            text-align: center;
            width: 100%;
            height: auto;
            border: none;
            background: #4460ac;
            color: #fff;
            padding: 8px 70px;
            font-size: 18px; }
        .main.passport form dl dd button {
          background: #4460ac;
          padding: 8px 70px;
          font-size: 18px; }
        .main.passport form dl dd .error-holder p {
          margin: 0;
          padding-bottom: 16px;
          color: rgba(217, 0, 27, 0.996078); }
          .main.passport form dl dd .error-holder p.success {
            display: block;
            color: #333; }
          .main.passport form dl dd .error-holder p.link {
            display: block;
            color: #333; }
    .main.passport form .notice {
      margin: 0;
      text-align: center;
      font-size: 12px;
      padding-bottom: 20px; }
  .main.passport .login {
    min-height: calc(100vh - 55px);
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .main.passport .login .success {
      display: none; }
    .main.passport .login .left {
      padding-right: 70px; }
      .main.passport .login .left h2 {
        font-size: 40px;
        font-weight: 700;
        color: #fff;
        line-height: 100%; }
      .main.passport .login .left h3 {
        font-size: 24px;
        font-weight: 400;
        color: #fff;
        line-height: 120%; }
      .main.passport .login .left ul {
        margin: 0;
        padding: 2em 0;
        padding-top: 1em; }
        .main.passport .login .left ul li {
          padding-left: 1.4em;
          line-height: 120%;
          color: #fff;
          font-size: 20px;
          list-style: none;
          position: relative; }
          .main.passport .login .left ul li::before {
            position: absolute;
            left: 4px;
            top: 10px;
            content: '';
            width: 12px;
            height: 12px;
            background: #fff;
            border-radius: 50%; }
      .main.passport .login .left .macbook {
        left: -60px;
        position: relative;
        width: 635px;
        height: 360px;
        background: url("/static/img/passport_macbook.svg") no-repeat center top; }
        .main.passport .login .left .macbook .swiper-container {
          position: absolute;
          left: 80px;
          top: 19px;
          width: 472px; }
          .main.passport .login .left .macbook .swiper-container img {
            width: 472px;
            height: 286px; }
    .main.passport .login .log-in {
      zoom: 1;
      width: 450px;
      padding: 40px 50px;
      padding-top: 0;
      background: #fff;
      border-radius: 10px;
      position: relative; }
      .main.passport .login .log-in .error-holder p {
        color: rgba(217, 0, 27, 0.996078); }
        .main.passport .login .log-in .error-holder p.success {
          display: block;
          color: #333; }
        .main.passport .login .log-in .error-holder p.link {
          display: block;
          color: #333; }
      .main.passport .login .log-in h4 {
        font-weight: 700;
        font-style: normal;
        font-size: 25px;
        color: #4460AC;
        padding-bottom: 16px;
        padding-top: 50px;
        position: relative;
        z-index: 2;
        background: #fff; }
        .main.passport .login .log-in h4 span {
          font-weight: 700;
          font-style: normal;
          font-size: 12px;
          color: #AAAAAA; }
      .main.passport .login .log-in .use_login_body {
        position: absolute;
        left: 50%;
        top: -170px;
        margin-left: -91px;
        z-index: 1; }
      .main.passport .login .log-in .use_login_hand {
        position: absolute;
        left: 50%;
        top: -50px;
        margin-left: -92px;
        z-index: 3; }
      .main.passport .login .log-in button[type=button] {
        background-color: #4460ac;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px; }
      .main.passport .login .log-in .submit-wrap .loader {
        display: none; }
      .main.passport .login .log-in .submit-wrap input {
        display: block;
        width: 100%;
        background-color: #4460ac;
        font-size: 20px;
        border-radius: 0px;
        text-align: center;
        padding: 10px 15px; }
      .main.passport .login .log-in .submit-wrap a {
        display: inline-block;
        margin-top: 20px;
        background: #fff;
        color: #4460ac; }
      .main.passport .login .log-in .netis_wechat {
        padding: 30px 0;
        padding-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .main.passport .login .log-in .netis_wechat img {
          width: 130px;
          flex-shrink: 0; }
        .main.passport .login .log-in .netis_wechat span {
          padding: 0 30px;
          font-size: 13px;
          color: #4661AD; }
  @media screen and (max-width: 720px) {
    .main.passport .new-account .warp {
      margin: 0 20px;
      width: calc(100% - 40px); }
    .main.passport h4 {
      font-size: 24px; }
    .main.passport form .stepBox {
      padding-top: 30px; }
    .main.passport form .stepBox {
      padding-top: 30px; }
    .main.passport form .stepBar {
      padding: 20px 10px 30px 10px; }
    .main.passport form .stepBar ul {
      zoom: 0.8; }
    .main.passport form dl {
      padding-bottom: 10px; }
    .main.passport form dl dt {
      width: 30vw; }
    .main.passport form dl dt {
      padding-right: 0; }
    .main.passport form dl dd {
      width: 60vw; }
    .main.passport form dl dd input {
      width: 100%; }
    .main.passport form dl dt {
      font-size: 16px; }
    .main.passport form .notice {
      padding: 20px; }
    .main.passport form .stepBox.success ul {
      display: block; }
    .main.passport form .stepBox.success h4 {
      display: block;
      text-align: center; }
    .main.passport form .stepBox.success h4 img {
      display: inline; }
    .main.passport form .stepBox.success h4 p {
      position: static;
      position: initial; }
    .main.passport form .stepBox.success ul li a {
      width: 100%; }
    .main.passport form dl dd .error {
      position: static;
      position: initial;
      display: block;
      padding: 0;
      padding-bottom: 10px; }
    .main.passport .login .log-in {
      margin: 0 20px;
      width: calc(100% - 40px); }
    .main.passport .login .log-in .use_login_body {
      zoom: 0.8; }
    .main.passport .login .log-in .use_login_hand {
      zoom: 0.8; }
    .main.passport .login[data-success=success] .success {
      margin: 0 20px;
      width: calc(100% - 40px); }
    .main.passport .login[data-success=success] .success .use_login_half {
      display: none; }
    .main.passport .login {
      padding: 20px 0px; }
      .main.passport .login .left {
        display: none; }
      .main.passport .login .log-in {
        padding: 20px 20px; }
        .main.passport .login .log-in h4 {
          padding-top: 0; }
        .main.passport .login .log-in .netis_wechat {
          padding-top: 10px; }
    .main.passport .new-account {
      padding: 20px 0px; }
      .main.passport .new-account .left {
        display: none; }
      .main.passport .new-account .reg form {
        margin: 0 auto;
        width: calc(100% - 40px);
        padding: 20px 20px; }
        .main.passport .new-account .reg form h4 {
          padding-top: 0; }
        .main.passport .new-account .reg form dl dd {
          width: 100%; } }
  @media screen and (max-width: 385px) {
    .main.passport form .stepBox {
      padding-top: 20px;
      padding-bottom: 20px; }
    .main.passport form .stepBar ul {
      zoom: 0.7; }
    .main.passport .login .log-in .use_login_body {
      zoom: 0.7; }
    .main.passport .login .log-in .use_login_hand {
      zoom: 0.7; }
    .main.passport form dl dd input {
      width: 100%; }
    .main.passport form dl.t dd button {
      right: -0%; }
    .main.passport form .notice {
      padding-top: 0; }
    .main.passport form .stepBox.success {
      padding: 20px 0; }
    .main.passport form .stepBox.success ul {
      padding: 0; }
    .main.passport form .stepBox.success ul li a {
      font-size: 20px; } }
  @media screen and (max-width: 320px) {
    .main.passport form .stepBar {
      padding: 20px 10px; }
    .main.passport form .stepBar ul {
      zoom: 0.6; }
    .main.passport h4 {
      font-size: 24px; }
      .main.passport h4 a {
        font-size: 13px; }
    .main.passport form dl dt {
      font-size: 13px; }
    .main.passport form dl dd button {
      font-size: 16px;
      padding: 8px 20px; }
    .main.passport form dl.t dd button {
      font-size: 14px;
      line-height: 28px; }
    .main.passport form .stepBox.success ul li a {
      font-size: 16px; }
    .main.passport .login .log-in .use_login_body {
      zoom: 0.6; }
    .main.passport .login .log-in .use_login_hand {
      zoom: 0.6; }
    .main.passport .login .log-in h4 {
      padding-top: 20px;
      padding-bottom: 0; }
    .main.passport .submit-wrap {
      margin-top: 15px; }
    .main.passport .login[data-success=success] .success {
      padding: 20px 0; }
    .main.passport .login .log-in .netis_wechat {
      display: none; } }

.cookies_policy {
  transition: all 0.3s ease-in-out;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  opacity: 0; }
  .cookies_policy.open {
    opacity: 1; }
  .cookies_policy.close {
    z-index: -1; }
  .cookies_policy .wrapper {
    width: 782px;
    padding: 0 72px 72px 72px;
    background: #fff;
    left: 50%;
    margin-left: -391px;
    top: 20%;
    position: absolute;
    border-radius: 10px; }
    .cookies_policy .wrapper h2 {
      font-size: 30px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0;
      width: 100%;
      text-align: left;
      margin-top: 64px;
      padding-bottom: 24px;
      border-bottom: 1px solid #E9ECF0; }
    .cookies_policy .wrapper p {
      font-size: 14px;
      color: #5F6464;
      letter-spacing: 0;
      line-height: 25px;
      padding-top: 24px;
      margin-bottom: 82px; }
      .cookies_policy .wrapper p a {
        color: #4460ac; }
    .cookies_policy .wrapper .btns {
      height: 40px; }
      .cookies_policy .wrapper .btns div {
        float: right;
        width: 200px;
        height: 40px;
        margin-left: 16px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        color: #2A2E2E;
        background: #F2F3F5;
        cursor: pointer; }
        .cookies_policy .wrapper .btns div.btn_accept {
          background: #4460ac;
          color: #fff; }
  @media screen and (max-width: 720px) {
    .cookies_policy .wrapper {
      position: static;
      position: initial;
      margin: 0 16px;
      margin-top: 30%;
      width: calc(100vw - 32px);
      padding: 16px; }
      .cookies_policy .wrapper h2 {
        font-size: 22px;
        margin-top: 24px;
        padding-bottom: 14px; }
      .cookies_policy .wrapper p {
        padding-top: 14px;
        margin-bottom: 22px; }
      .cookies_policy .wrapper .btns {
        height: auto; }
        .cookies_policy .wrapper .btns div {
          float: none;
          width: 100%;
          margin: 0;
          margin-bottom: 14px; } }
  @media screen and (max-width: 320px) {
    .cookies_policy .wrapper {
      margin-top: 70px; }
      .cookies_policy .wrapper h2 {
        font-size: 22px;
        margin-top: 0px;
        padding-bottom: 14px; }
      .cookies_policy .wrapper p {
        padding-top: 0px;
        margin-bottom: 22px; } }

/* home primary */
.primary {
  /*
	position:relative;
	min-height:500px;
	padding-right: 310px;
	margin-bottom:25px;
	*/
  display: flex;
  margin-top: 15px;
  min-height: 500px; }

.primary .content {
  /*
	min-height:500px;
	*/
  padding: 20px;
  margin-right: 15px;
  flex-grow: 1;
  width: 795px; }

.primary .side {
  /*
	position:absolute;
	top:0px;
	right:0px;
	width:290px;
	*/
  flex-basis: 290px; }

/* summary */
.summary {
  margin-bottom: 15px;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: #e5e5e5 solid 1px; }

.summary .title {
  color: #4994c8;
  font-weight: bold; }

/* download */
.download {
  margin-bottom: 15px; }

.download .link {
  color: #4994c8;
  font-weight: bold; }

/* sp */
.account {
  font-size: 14px;
  color: #6e7070; }

.account .circle {
  background-color: #4994c8;
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 8px; }

.account .link {
  color: #4994c8; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }

.input-group-append {
  margin-left: -1px; }

.input-group-append, .input-group-prepend {
  display: flex; }

.form-control {
  display: block;
  /* width: 100%; */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.form-control::-ms-expand {
  background-color: transparent;
  border: 0; }

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::placeholder {
  color: #6c757d;
  opacity: 1; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

/* patch */
.form-holder button:not(.link) {
  background-color: #4994c8; }

.submit-wrap input[type=submit] {
  background-color: #4994c8; }

/* forbidden if have not login
/* forbidden */
.forbidden {
  display: inline-block;
  max-width: 560px;
  min-width: 350px;
  border: solid 1px #4994c8;
  background-color: rgba(73, 148, 200, 0.15);
  padding: 16px; }

.forbidden p {
  margin-top: 0px;
  color: #6e7070;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8; }

.forbidden button {
  background-color: transparent;
  display: inline;
  padding: 0;
  text-decoration: underline;
  color: #4661ad;
  font-weight: 700;
  transition: opacity .3s;
  font-size: 15px; }

