* { margin:0; padding:0; box-sizing:border-box; 
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent; /* For some Androids */
}


img { display:block; max-width:100% }

.img-bg-cover { background-size:cover; background-position:center }
@mixin img-bg-cover { background-size:cover; background-position:center }

.img-bg-contain { background-size:contain; background-position:center; background-repeat:no-repeat }

.abs-fill { position:absolute; top:0; left:0; width:100%; height:100% }
@mixin abs-fill { position:absolute; top:0; left:0; width:100%; height:100% }


.fixed-fill  { position:fixed; top:0; left:0; width:100%; height:100% }
@mixin fixed-fill  { position:fixed; top:0; left:0; width:100%; height:100% }



@mixin abs-center { position:absolute; top:0; bottom:0; left:0; right:0; margin:auto }



iframe { width:600px; height:400px; margin-top:80px }


@mixin for-small-desktop {
  @media(max-width:1100px) { @content; }
}

@mixin for-divi-mobile {
  @media(max-width:981px) { @content; }
}


@mixin for-ipad-v {
  @media(max-width:768px) { @content; }
}
@mixin for-phone {
  @media(max-width:600px) { @content; }
}

@mixin for-phone-smaller {
  @media(max-width:450px) { @content; }
}


