.template { 
  &.grid, &.cards, &.landing {
    .items { display:grid; grid-gap:50px 20px; margin-top:50px; 
      @include for-small-desktop { grid-gap:30px 20px; margin-top:30px; }
      .item { background-color:white; 
        .text { padding:22px 28px 18px; 
          @include for-phone { padding: 18px 18px 16px}
          .headline { display:flex;
            .icon { width:18px; margin-right:8px; margin-top:-1px; flex-shrink:0 }
          }
          .details {
            .divider { margin:0 5px }
            .showDate { opacity:.6; font-size:12px; white-space:nowrap }
          }
          .link { display:block; text-decoration:none; 
            &.more { margin-top:35px; text-align:right; }
          }
        }
      }
    }
  }
  &.grid, &.cards {
    .items { grid-template-columns:repeat(3, 1fr); 
      @include for-phone-smaller { grid-template-columns:repeat(1, 1fr);  }
      .item { 
        .image { background-color:$blue-2-25; 
          img { background-color:white }
        }
        .text { flex-grow:1; display:flex; flex-direction:column; justify-content:space-between }
      } 
    }
  }
  &.grid {
    .items {
      @include for-small-desktop { grid-template-columns:repeat(2, 1fr); }
      @include for-phone-smaller { grid-template-columns:repeat(1, 1fr); }
      .item { display:flex; flex-direction:column; }
    }
  }
  &.cards .items { grid-template-columns:repeat(2, 1fr); 
    .item { display:flex;
      .image { width:45%;  }
      .text { width:55%;
        .contents {
          p { margin-top:9px; line-height:1.5 }
        }
        .link.more { margin-top:10px }
      }
    }
    @include for-small-desktop { grid-template-columns:repeat(1, 1fr); 
      .item { 
        .image { width:180px; flex-shrink:0 }
        .text { width:auto }
      }
    }
    @include for-phone { 
      .item { 
        .image { width:45% }
        .text { width:55% }
      }
    }
    @include for-phone-smaller { 
      .item { display:block;
        .image { width:100%;
          // img { width:50% }
        }
        .text { width:100%;
          .link.more { margin-top:35px }
        }
      }
    }

  }
  &.landing .items { grid-template-columns:repeat(2, 1fr); 
    .item { text-align:center; display:flex; justify-content:center;
      .text { display:flex; flex-direction:column; justify-content:space-between;
        h4 { display:inline-block; position:relative; 
          .icon { position:absolute; left:-26px; width:17px; }
        }
        .link.more { text-align:center }
      }
    }
    @include for-phone { margin-top:30px; grid-gap:30px 20px;
      .item { grid-column:span 2 }
    }
  }
  &.table .items { width:100%; background-color:white;
    .item { display:flex; justify-content:space-between; padding:10px 18px; font-size:14px;
      .icon { width:18px; margin-right:8px; margin-top:-3px }
      .two, .three, > .link { margin-left:15px }
      > .link { white-space: nowrap }
      .spacer { flex-grow:1 }
      &:nth-of-type(even) { background-color:$green-10 }
      @include for-phone { display:block; padding:10px 13px;
        .two, .three, > .link { margin-left:0px }
        > .link { text-align:right}
      }
    }
    @include for-phone-smaller { 
      .item { 
        .link.more { display:block; margin-top:15px }
      }
    }
  }
}





.is-ie { 
  .template { 
    &.grid, &.cards, &.landing {
      .items { display:flex; flex-wrap:wrap; justify-content:space-between; 
        .item { flex-basis:48%; margin-bottom:40px }
      }
    }
    &.grid {
      .items .item { flex-basis:31%; display:block }
    }
  }
}