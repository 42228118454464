#container > .section {
  .navbar { border-bottom:solid 1px $grey-rgb240;
    .inner { background-color:white; display:flex; justify-content:space-between; 
      .menu { display:flex; 
        a { color:$blue-2; font-weight:700; font-size:14px; padding:17px 30px 12px; text-decoration:none; border-bottom:solid 5px transparent; transition:border-color .3s;
        }
      }
    }
  }
  .status { font-size:14px; color:$grey; padding:16px 0px 13px 30px;
    button { margin-left:8px; }
    .circle { display:inline-block; width:9px; height:9px; border-radius:50%; background-color:grey; margin-right:8px  }
    &.mobile { display:none }
  }
}


@include for-small-desktop { 
  #container > .section {
    .navbar { padding-top:0; background-color:rgb(51,51,51); _transition:padding .5s;
      .inner { position:relative; display:block;
        .menu { 
          a { justify-content:center; width:25%; font-size:13px; text-align:center; padding:13px 0 10px; border-left:solid 1px $grey-rgb240;
            @include for-phone-smaller {
              .icon { width:12px; margin-right:4px; margin-top:1px }
            }
            &:first-of-type { border-left:0 }
            &.active { border-bottom-width:4px }
          }
          @media(max-width:370px) {
            a { font-size:12px;
              .icon { margin-right:1px }
            }              
          }

        }
      }
    }
    .status {
      &.desktop { display:none }
      &.mobile { display:block; position:absolute; top:46px; right:0; padding-right:34px; font-size:12px;
        .link { font-size:12px }
      }
    }
  }
  #mobileThumb { transition:transform .5s }
  body.menu-up {
    #container > .section .navbar { padding-top:0 }
    #mobileThumb { transform:translateY(-43px) }
  }
}  
  

