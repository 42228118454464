/* home search */
.search {
    display:flex;
    padding-top:12px;
    padding-bottom:40px;
    justify-content: space-between;
    /* margin-bottom: 15px; */
}
.search .product {
    flex-grow: 1;
    margin-right:15px;
}
.search .content {
    flex-grow: 1;
    margin-right:15px;
}
.search .title {
    font-size: 24px;
    color: #333333;
    line-height: 45px;
}
.search .title a {
    font-size: 24px;
    color: #333333;
    line-height: 45px;
}
.search .title span {
    color:#8dbd85;
}
.search .value {
    margin-right: 15px;
    padding: 0px 8px;
    height: 42px;
    width: 300px;
}
.search button {
    flex-grow: 1;
    flex-basis: 150px;
}