/* user */
// url error
// node_modules/resolve-url-loader/index.js 
// var options change removeCR from "false" to "true".
.zoom2{
    .top{
        h4{
            font-size: 20px;
        }
        p{
            font-size: 16px;
        }
    }
    .main{
        .body *{
            font-size: 16px;
        }
        .image{
            width: 360px !important;
            max-width: 360px !important;
            .passport{
                margin-top: 16px;
                background: rgba(73, 148, 200, 0.15);
                padding: 16px;
                p{
                    font-size: 14px;
                    margin: 0;
                    padding-bottom: 1em;
                }
                button{
                    display: block;
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }
}
// sp
@media screen and (max-width: 720px){
    .zoom2 .main .image {
        width: auto !important;
        max-width: initial !important;
    }
}
.main.passport{
    opacity: 1;
    min-height: calc(100vh - 55px);
    background: url('/static/img/use_bg.svg') no-repeat center bottom;
    background-size: cover;
    .loading{
        display: none !important;
    }
    .oops{
        h1{
            color: #fff;
        }
    }
    h4{
        margin: 0;
        font-weight: 700;
        font-style: normal;
        font-size: 24px;
        color: #4661AD;
        position: relative;
        &.header{
            padding-top: 3%;
        }
        a{
            position: absolute;
            right: 0;
            bottom: 6px;
            font-weight: 350;
            font-style: normal;
            font-size: 16px;
            letter-spacing: 1px;
            color: #FFFF00;
        }
    }
    .new-account{
        zoom: 1;
        min-height: calc(100vh - 55px);
        display: flex;
        justify-content:center;
        align-items:center;
        .left{
            padding-right: 70px;
            h2{
                font-size: 40px;
                font-weight: 700;
                color: #fff;
                line-height: 120%;
            }
            ul{
                margin: 0;
                padding: 2em 0;
                padding-top: 1em;
                li{
                    padding-left: 1.4em;
                    line-height: 120%;
                    color: #fff;
                    font-size: 20px;
                    list-style: none;
                    position: relative;
                    &::before{
                        position: absolute;
                        left: 4px;
                        top: 10px;
                        content: '';
                        width: 12px;
                        height: 12px;
                        background: #fff;
                        border-radius: 50%;
                    }
                }
            }
            .macbook{
                left: -60px;
                position: relative;
                width: 635px;
                height: 360px;
                background: url('/static/img/passport_macbook.svg') no-repeat center top;
                .swiper-container{
                    position: absolute;
                    left: 80px;
                    top: 19px;
                    width: 472px;
                    img{
                        width: 472px;
                        height: 286px;
                    }
                }
            }
        }
        .reg{
            width: 450px;
            form{
                padding: 40px 50px;
            }
        }
        .textR{
            text-align: right;
            a{
                color: #4460ac;
            }
        }
    }
    form{
        background: #fff;
        border-radius: 10px;
        .stepBar{
            padding: 20px;
            padding-bottom: 40px;
            background: #7e8ec6;
            border-radius: 10px;
            &[data-step=step1]{
                ul li:nth-child(1){
                    color: #4460AC;
                    background: #fff;
                    span{
                        display: block;
                    }
                }
            }
            &[data-step=step2]{
                ul li:nth-child(2){
                    color: #4460AC;
                    background: #fff;
                    span{
                        display: block;
                    }
                }
            }
            &[data-step=step3]{
                ul li:nth-child(3){
                    color: #4460AC;
                    background: #fff;
                    span{
                        display: block;
                    }
                }
            }
            ul{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 0 auto;
                width: 473px;
                height: 73px;
                list-style: none;
                background: url('/static/img/use_stepbar_bg.svg') no-repeat 0 0;
                li{
                    position: relative;
                    margin: 0;
                    width: 45px;
                    border-radius: 50%;
                    margin: 14px;
                    text-align: center;
                    line-height: 43px;
                    color: #fff;
                    font-size: 20px;
                    font-weight: 700;
                    span{
                        display: none;
                        position: absolute;
                        left: -26px;
                        bottom: -50px;
                        font-weight: 350;
                        font-style: normal;
                        font-size: 15px;
                        color: #FFFFFF;
                        width: 100px;
                        text-align: center;
                    }
                    &.active{
                        color: #4460AC;
                        background: #fff;
                        span{
                            display: block;
                        }
                    }
                }
            }
        }
        .stepBox{
            padding: 30px 0 0px 0;
            &.success{
                padding: 30px 0;
                h3{
                    display: flex;
                    justify-content:center;
                    align-items:center;
                    padding-bottom: 10px;
                }
                h4{
                    position: relative;
                    display: flex;
                    justify-content:center;
                    align-items:center;
                    p{
                        margin: 0;
                        position: absolute;
                        left: 62%;
                        color: #4661ad;
                    }
                }
                ul{
                    margin: 0;
                    padding: 20px 0;
                    list-style: none;
                    display: flex;
                    justify-content:center;
                    align-items:center;
                    li{
                        padding: 0 60px;
                        a{
                            display: block;
                            width: 216px;
                            text-align: center;
                            padding: 6px 0;
                            background-color: rgba(68, 96, 172, 1);
                            border-radius: 30px;
                            font-size: 23px;
                            color: #fff;
                        }
                    }
                }
            }
        }
        dl{
            padding-bottom: 16px;
            &.t{
                padding-bottom: 16px;
                dd{
                    position: relative;
                    button{
                        position: absolute;
                        right: 0;
                        top: 0;
                        background: #4460ac;
                        padding: 9px 20px;
                        font-size: 18px;
                        &[disabled]{
                            background: #d7d7d7;
                            color: #555;
                        }
                    }
                }
            }
            dt{
                font-weight: 500;
                font-style: normal;
                font-size: 23px;
                text-align: right;
                width: 150px;
                padding-right: 10px;
                span{
                    color: #D9001B;
                }
            }
            dd{
                position: relative;
                .error{
                    position: absolute;
                    left: 190px;
                    top: 20px;
                    font-size: 12px;
                    color: #D9001B;
                    padding-left: 20px;
                }
                input{
                    margin: 0;
                    height: 46px;
                    padding: 3px 2px 3px 10px;
                    font-family: 'Arial Normal', 'Arial';
                    font-weight: 400;
                    font-style: normal;
                    font-size: 16px;
                    letter-spacing: normal;
                    vertical-align: none;
                    text-align: left;
                    text-transform: none;
                    border: 1px solid #000;
                    &[type=submit]{
                        text-align: center;
                        width: 100%;
                        height: auto;
                        border: none;
                        background: #4460ac;
                        color: #fff;
                        padding: 8px 70px;
                        font-size: 18px;
                    }
                }
                button{
                    background: #4460ac;
                    padding: 8px 70px;
                    font-size: 18px;
                }
                .error-holder{
                    p{
                        margin: 0;
                        padding-bottom: 16px;
                        color: rgba(217, 0, 27, 0.996078431372549);
                        &.success{
                            display: block;
                            color: #333;
                        }
                        &.link{
                            display: block;
                            color: #333;
                        }
                    }
                }
            }
        }
        .notice{
            margin: 0;
            text-align: center;
            font-size: 12px;
            padding-bottom: 20px;
        }
    }
    .login{
        min-height: calc(100vh - 55px);
        padding: 50px 0;
        display: flex;
        justify-content:center;
        align-items:center;
        .success{
            display: none;
        }
        .left{
            padding-right: 70px;
            h2{
                font-size: 40px;
                font-weight: 700;
                color: #fff;
                line-height: 100%;
            }
            h3{
                font-size: 24px;
                font-weight: 400;
                color: #fff;
                line-height: 120%;
            }
            ul{
                margin: 0;
                padding: 2em 0;
                padding-top: 1em;
                li{
                    padding-left: 1.4em;
                    line-height: 120%;
                    color: #fff;
                    font-size: 20px;
                    list-style: none;
                    position: relative;
                    &::before{
                        position: absolute;
                        left: 4px;
                        top: 10px;
                        content: '';
                        width: 12px;
                        height: 12px;
                        background: #fff;
                        border-radius: 50%;
                    }
                }
            }
            .macbook{
                left: -60px;
                position: relative;
                width: 635px;
                height: 360px;
                background: url('/static/img/passport_macbook.svg') no-repeat center top;
                .swiper-container{
                    position: absolute;
                    left: 80px;
                    top: 19px;
                    width: 472px;
                    img{
                        width: 472px;
                        height: 286px;
                    }
                }
            }
        }
        .log-in{
            zoom: 1;
            width: 450px;
            padding: 40px 50px;
            padding-top: 0;
            background: #fff;
            border-radius: 10px;
            position: relative;
            .error-holder{
                p{
                    color: rgba(217, 0, 27, 0.996078431372549);
                    &.success{
                        display: block;
                        color: #333;
                    }
                    &.link{
                        display: block;
                        color: #333;
                    }
                }
            }
            h4{
                font-weight: 700;
                font-style: normal;
                font-size: 25px;
                color: #4460AC;
                padding-bottom: 16px;
                padding-top: 50px;
                position: relative;
                z-index: 2;
                background: #fff;
                span{
                    font-weight: 700;
                    font-style: normal;
                    font-size: 12px;
                    color: #AAAAAA;
                }
            }
            .use_login_body{
                position: absolute;
                left: 50%;
                top: -170px;
                margin-left: -91px;
                z-index: 1;
            }
            .use_login_hand{
                position: absolute;
                left: 50%;
                top: -50px;
                margin-left: -92px;
                z-index: 3;
            }
            button[type=button]{
                background-color: rgba(68, 96, 172, 1);
                font-size: 18px;
                padding-left: 10px;
                padding-right: 10px;
            }
            .submit-wrap{
                .loader{
                    display: none;
                }
                input{
                    display: block;
                    width: 100%;
                    background-color: rgba(68, 96, 172, 1);
                    font-size: 20px;
                    border-radius: 0px;
                    text-align: center;
                    padding: 10px 15px;
                }
                a{
                    display: inline-block;
                    margin-top: 20px;
                    background: #fff;
                    color: rgba(68, 96, 172, 1);
                }
            }
            .netis_wechat{
                padding: 30px 0;
                padding-left: 15px;
                display: flex;
                justify-content:center;
                align-items:center;
                img{
                    width: 130px;
                    flex-shrink:0;
                }
                span{
                    padding: 0 30px;
                    font-size: 13px;
                    color: #4661AD;
                }
            }
        }
    }
    // sp
    @media screen and (max-width: 720px){ // default
        .new-account .warp{
            margin: 0 20px;
            width: calc(100% - 40px);
        }
        h4{
            font-size: 24px;
        }
        form .stepBox{
            padding-top: 30px;
        }
        form .stepBox{
            padding-top: 30px;
        }
        form .stepBar{
            padding: 20px 10px 30px 10px;
        }
        form .stepBar ul{
            zoom: 0.8;
        }
        form dl{
            padding-bottom: 10px;
        }
        form dl dt{
            width: 30vw;
        }
        form dl dt{
            padding-right: 0;
        }
        form dl dd{
            width: 60vw;
        }
        form dl dd input{
            width: 100%;
        }
        form dl dt{
            font-size: 16px;
        }
        form .notice{
            padding: 20px;
        }
        form .stepBox.success ul{
            display: block;
        }
        form .stepBox.success h4{
            display: block;
            text-align: center;
        }
        form .stepBox.success h4 img{
            display: inline;
        }
        form .stepBox.success h4 p{
            position:initial;
        }
        form .stepBox.success ul li a{
            width: 100%;
        }
        form dl dd .error{
            position: initial;
            display: block;
            padding: 0;
            padding-bottom: 10px;
        }
        .login .log-in{
            margin: 0 20px;
            width: calc(100% - 40px);
        }
        .login .log-in .use_login_body{
            zoom: 0.8;
        }
        .login .log-in .use_login_hand{
            zoom: 0.8;
        }
        .login[data-success=success] .success{
            margin: 0 20px;
            width: calc(100% - 40px);
        }
        .login[data-success=success] .success .use_login_half{
            display: none;
        }
        .login{
            padding: 20px 0px;
            .left{
                display: none;
            }
            .log-in{
                padding: 20px 20px;
                h4{
                    padding-top: 0;
                }
                .netis_wechat{
                    padding-top: 10px;
                }
            }
        }
        .new-account{
            padding: 20px 0px;
            .left{
                display: none;
            }
            .reg form{
                margin: 0 auto;
                width: calc(100% - 40px);
                padding: 20px 20px;
                h4{
                    padding-top: 0;
                }
                dl{
                    dd{
                        width: 100%;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 385px){ // iphone 678
        form .stepBox{
            padding-top: 20px;
            padding-bottom: 20px;
        }
        form .stepBar ul{
            zoom: 0.7;
        }
        .login .log-in .use_login_body{
            zoom: 0.7;
        }
        .login .log-in .use_login_hand{
            zoom: 0.7;
        }
        form dl dd input{
            width: 100%;
        }
        form dl.t dd button{
            right: -0%;
        }
        form .notice{
            padding-top: 0;
        }
        form .stepBox.success{
            padding: 20px 0;
        }
        form .stepBox.success ul{
            padding: 0;
        }
        form .stepBox.success ul li a{
            font-size: 20px;
        }
    }
    @media screen and (max-width: 320px){ // iphone 5
        form .stepBar{
            padding: 20px 10px;
        }
        form .stepBar ul{
            zoom: 0.6;
        }
        h4{
            font-size: 24px;
            a{
                font-size: 13px;
            }
        }
        form dl dt{
            font-size: 13px;
        }
        form dl dd button{
            font-size: 16px;
            padding: 8px 20px;
        }
        form dl.t dd button{
            font-size: 14px;
            line-height: 28px;
        }
        form .stepBox.success ul li a{
            font-size: 16px;
        }
        .login .log-in .use_login_body{
            zoom: 0.6;
        }
        .login .log-in .use_login_hand{
            zoom: 0.6;
        }
        .login .log-in h4{
            padding-top: 20px;
            padding-bottom: 0;
        }
        .submit-wrap{
            margin-top: 15px;
        }
        .login[data-success=success] .success{
            padding: 20px 0;
        }
        .login{
            .log-in{
                .netis_wechat{
                    display: none;
                }
            }
        }
    }
    // sp end
}