/* home */
/* home title */
.home-title {
    width: 100%;
    font-size: 24px;
    padding-top: 12px;
    color: #333333;
}
.main-title {
	width: 100%;
    font-size: 24px;
    padding-top: 12px;
    color: #333333;
    margin-bottom:18px;
}
/* home categories */
.categories {
	display:flex;
	justify-content: space-around;
	padding-top:15px;
	padding-bottom:15px;
}
.categories .category {
	flex-grow:1;
	text-align: center;
    height: 54px;
    line-height: 54px;
    color: #FFFFFF;
    font-size: 16px;
    background: #6a7a86;
    font-weight:bold;
    margin-right:3px;
}
.categories .category:last-child {
    margin-right:0px;
}
.categories .category:hover {
	background-color:#8dbd85;
}
.categories .category a {
    color:white;
}
.categories .active {
	background-color:#8dbd85;
}
/* model */
.models {

}
.models .model {
	flex-basis:calc(50% - 7.5px);
	padding:30px;
	margin-top:15px;
}
.models .model .title {
	color: #8dbd85;
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 20px;
}
.models .model a {
	width: 100%;
    display: inline-block;
    border-bottom: #e5e5e5 solid 1px;
    line-height: 60px;
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    height: 60px;
}
.models .model .count {
	flex-basis:100px;
	background: url(/static/img/icon1.png) no-repeat 50% 12px,url(/static/img/icon2.png) no-repeat 50%;
    padding-top: 40px;
    height: 123px;
	margin-left:30px;
}
.models .model .count .ijosf{
	font-size: 14px;
    color: #666666;
    line-height: 20px;
    text-align: center;
    width: 100%;
}
.models .model .count .pseff {
	font-size: 14px;
    color: #4994c8;
    line-height: 20px;
    text-align: center;
    width: 100%;
}