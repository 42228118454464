.recommends {
    margin-bottom: 15px;
    padding: 30px;
}
.recommends .title {
    font-size: 18px;
    color: #999999;
    line-height: 2em;
    width: 100%;
}
.recommends .recommend {
    width: 100%;
    font-size: 14px;
    line-height: 45px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    height: 46px;
}