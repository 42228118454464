#login-overlay { @include fixed-fill; z-index:11; 
	.bg { @include abs-fill; background-color:rgba(0,0,0,.7); }
	.scroll-wrap { overflow-y:auto; display:flex; height:100%; padding:7vw;
		.center-wrap { margin-top:auto; margin-bottom:auto; width:100%;
			.content { height:100%; display:flex; align-items:center; justify-content:center;
				.form-holder { position:relative; z-index:1; width:380px; padding:40px; background-color:white; padding:35px;
					form { margin-top:25px; }
					&.login { width:760px; max-width:100%; display:flex;
						> div { width:50%; flex-grow:0; flex-shrink:0 }
						.new-account { padding-right:50px;
							h4 + p { margin-top:22px; }
						}
						.log-in { padding-left:50px; border-left:solid 1px $grey-50 }
						@include for-small-desktop { width:380px; flex-direction:column-reverse; 
							> div { width:100% }
							.log-in { padding-bottom:30px; padding-left:0; border-left:none; border-bottom:solid 1px $grey-50 }
							.new-account { padding-top:30px; padding-right:0; }
						}
					}
				}	
			}
		}
	}
}




@include for-ipad-v { 
	#login-overlay { 
		.scroll-wrap { padding-left:7vw;
			.center-wrap {
				.content {
					.form-holder { padding:30px; }
				}
			}
		}
	}
}
