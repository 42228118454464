#container { _margin-left:200px; min-height:100vh; background-color:rgb(240,240,240); padding-top:54px;
  // @include for-small-desktop { padding }
  > .section { position:relative;
    .navbar { position:fixed; z-index:1; top:54px; width:100%; background-color:white; padding:0 7vw;
      @include for-small-desktop { padding:0 }
      > .inner { max-width:1100px; margin:auto; 
        .menu {
          a { display:flex;
            .icon { width:16px; margin-right:7px; margin-top:-1px }
          }
        }
      }
    }
    > .main { padding:120px 7vw 90px; _min-height:100vh; 
      .template { max-width:1100px; margin:auto }
      @include for-small-desktop { padding:145px 7vw 70px }
    }
  }
}


// DIVI MENU
#main-header { padding:0 7vw;
  .et_menu_container { max-width:1100px; margin:auto }
}
