@charset "UTF-8";
@import url(./utilities/grid.css);
@import url(./utilities/background_color.css);
@import url(./utilities/fore_color.css);
@import url(./utilities/cursor.css);
@import url(./utilities/button.css);
@import url(./utilities/breadcrumb.css);
@import url(./utilities/flex.css);
@import url(./utilities/text.css);
@import url(./utilities/margin_and_padding.css);
@import url(./utilities/cursor.css);
@import url(./page/home.css);
@import url(./module/tag.css);
@import url(./module/article.css);
@import url(./module/recommend.css);
@import url(./module/search.css);
@import url(./module/flex.css);
@import url(./module/foot.css);
@import url(./module/entrance.css);
@import url(./module/team.css);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  /* For some Androids */ }

img {
  display: block;
  max-width: 100%; }

.img-bg-cover {
  background-size: cover;
  background-position: center; }

.img-bg-contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.abs-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.fixed-fill {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

iframe {
  width: 600px;
  height: 400px;
  margin-top: 80px; }

#container .section.data .navbar .menu a:hover {
  border-bottom-color: rgba(73, 148, 200, 0.5); }

#container .section.data .navbar .menu a.active {
  border-bottom-color: #4994c8; }

#container .section.data .status .circle {
  background-color: #4994c8; }

#container .section.data .breadcrumb {
  background-color: #4994c8; }

#container .section.data .link {
  color: #4994c8; }
  #container .section.data .link h4 {
    color: #4994c8; }

#container .section.data .template.inner .locked {
  border: solid 1px #4994c8; }

#container .section.data .icon .green {
  display: none; }

#container .section.data .close-x:before, #container .section.data .close-x:after {
  background-color: #4994c8; }

#container .section.data form input[type=submit], #container .section.data .button, #container .section.data button:not(.link) {
  background-color: #4994c8; }

#container .section.college .navbar .menu a:hover {
  border-bottom-color: rgba(73, 148, 200, 0.5); }

#container .section.college .navbar .menu a.active {
  border-bottom-color: #4994c8; }

#container .section.college .status .circle {
  background-color: #4994c8; }

#container .section.college .breadcrumb {
  background-color: #4994c8; }

#container .section.college .link {
  color: #4994c8; }
  #container .section.college .link h4 {
    color: #4994c8; }

#container .section.college .template.inner .locked {
  border: solid 1px #4994c8; }

#container .section.college .icon .green {
  display: none; }

#container .section.college .close-x:before, #container .section.college .close-x:after {
  background-color: #4994c8; }

#container .section.college form input[type=submit], #container .section.college .button, #container .section.college button:not(.link) {
  background-color: #4994c8; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, "PingFang SC", "萍方-简", "Hiragino Sans GB", 冬青黑体, STXihei, 华文细黑, "Microsoft YaHei", YaHei, 微软雅黑体, sans-serif; }

a {
  color: #4994c8;
  cursor: pointer; }

h1, h2, h3, h4, h5, h6 {
  color: #4661ad;
  margin-top: 16px; }
  h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
    margin-top: 0; }

h3 {
  font-size: 21px; }

h4 {
  color: #4661ad;
  _font-size: 16px;
  font-weight: 700; }
  h4 span {
    font-weight: 400;
    margin-left: 10px; }

.table h4 {
  font-size: 14px; }
  .table h4 span {
    margin-left: 0; }

p, pre, blockquote {
  color: #6e7070;
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
  line-height: 1.8; }

.table {
  color: #6e7070;
  font-size: 14px;
  font-weight: 400; }

.content .body p {
  word-wrap: break-word; }

blockquote {
  padding-left: 15px;
  border-left: solid 3px rgba(73, 148, 200, 0.5); }

h1 + p, h1 + pre, h2 + p, h2 + pre, h3 + p, h3 + pre, h4 + p, h4 + pre, h5 + p, h5 + pre, h6 + p, h6 + pre {
  margin-top: 16px; }

a.link {
  font-size: 14px;
  font-weight: 700;
  text-decoration: none; }

ul li, ol li {
  color: #6e7070;
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px; }

ul, ol {
  margin-top: 12px;
  margin-left: 20px; }

.content .body > div > *:first-child {
  margin-top: 0; }

.content .body p strong {
  display: block;
  margin-top: 24px; }

@media (max-width: 450px) {
  h4 {
    font-size: 15px; }
  p, pre, blockquote {
    font-size: 13px; } }

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease 300ms; }

.in-view.fade-in,
.in-view .fade-in {
  opacity: 1; }

.in-view.fade-in-up,
.in-view .fade-in-up {
  opacity: 1;
  transform: translateY(0); }

.in-view-wrap.fade-in {
  opacity: 1; }

.stagger-100 {
  transition-delay: 400ms; }

.stagger-200 {
  transition-delay: 500ms; }

.stagger-300 {
  transition-delay: 600ms; }

.stagger-400 {
  transition-delay: 700ms; }

.stagger-500 {
  transition-delay: 800ms; }

.stagger-600 {
  transition-delay: 900ms; }

.stagger-700 {
  transition-delay: 1000ms; }

.stagger-800 {
  transition-delay: 1100ms; }

.stagger-900 {
  transition-delay: 1200ms; }

.stagger-1000 {
  transition-delay: 1300ms; }

.stagger-1100 {
  transition-delay: 1400ms; }

.stagger-1200 {
  transition-delay: 1500ms; }

.stagger-1300 {
  transition-delay: 1600ms; }

.stagger-1400 {
  transition-delay: 1700ms; }

.stagger-1500 {
  transition-delay: 1800ms; }

.stagger-1600 {
  transition-delay: 1900ms; }

.stagger-1700 {
  transition-delay: 2000ms; }

.stagger-1800 {
  transition-delay: 2100ms; }

.stagger-1900 {
  transition-delay: 2200ms; }

.stagger-2000 {
  transition-delay: 2300ms; }

.breadcrumb {
  color: white;
  font-weight: 700;
  font-size: 16px;
  padding: 15px 18px; }
  .breadcrumb span {
    margin: 0 10px; }
  .breadcrumb a {
    color: white;
    text-decoration: none; }
  @media (max-width: 1100px) {
    .breadcrumb {
      font-size: 14px;
      padding: 13px; } }
  @media (max-width: 600px) {
    .breadcrumb {
      padding: 13px 5.5vw; } }

.template.inner .content {
  background-color: white;
  padding: 60px; }
  @media (max-width: 1100px) {
    .template.inner .content {
      padding: 40px 7vw; } }
  @media (max-width: 600px) {
    .template.inner .content {
      padding: 40px 5.5vw; } }
  .template.inner .content .top {
    padding-bottom: 30px; }
  .template.inner .content .main.videos.false {
    flex-direction: column; }
  .template.inner .content .main {
    border-top: solid 1px rgba(110, 112, 112, 0.5);
    padding-top: 30px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between; }
    .template.inner .content .main > .text {
      padding-right: 60px;
      max-width: 560px;
      min-width: 350px;
      flex-shrink: 1; }
      .template.inner .content .main > .text .body > * {
        max-width: 100%; }
      .template.inner .content .main > .text .body > *:first-child {
        margin-top: 0; }
      .template.inner .content .main > .text .body video {
        max-width: 100%;
        height: auto; }
      .template.inner .content .main > .text .body video, .template.inner .content .main > .text .body audio, .template.inner .content .main > .text .body img {
        margin-top: 20px;
        outline: none; }
      .template.inner .content .main > .text .submit-wrap {
        position: relative;
        margin-top: 30px;
        display: flex;
        align-items: center; }
        .template.inner .content .main > .text .submit-wrap .button {
          display: flex;
          align-items: center;
          background-color: #4994c8;
          padding: 8px 20px 8px 15px;
          border-radius: 3px;
          transition: background-color 0.15s; }
          .template.inner .content .main > .text .submit-wrap .button:hover {
            background-color: rgba(73, 148, 200, 0.8) !important; }
          .template.inner .content .main > .text .submit-wrap .button:disabled {
            background-color: rgba(73, 148, 200, 0.8) !important;
            opacity: .8 !important; }
          .template.inner .content .main > .text .submit-wrap .button .icon {
            width: 25px;
            margin-right: 8px; }
          .template.inner .content .main > .text .submit-wrap .button p {
            margin-top: 0;
            font-size: 14px;
            font-weight: 800;
            color: white; }
      .template.inner .content .main > .text .protected-content .locked {
        padding: 16px;
        display: inline-block;
        background-color: rgba(73, 148, 200, 0.15); }
        .template.inner .content .main > .text .protected-content .locked > .inner {
          display: flex; }
          .template.inner .content .main > .text .protected-content .locked > .inner .icon {
            width: 19px;
            flex-shrink: 0;
            margin-right: 8px;
            margin-top: -2px; }
          .template.inner .content .main > .text .protected-content .locked > .inner .text > *:first-of-type {
            margin-top: 0; }
          .template.inner .content .main > .text .protected-content .locked > .inner .text p + p {
            margin-top: 8px; }
          .template.inner .content .main > .text .protected-content .locked > .inner .text .link {
            margin-top: 8px; }
      .template.inner .content .main > .text .body + .protected-content {
        margin-top: 40px; }
    .template.inner .content .main .image, .template.inner .content .main .video, .template.inner .content .main .blog-links {
      width: 300px;
      flex-shrink: 0;
      flex-grow: 1;
      max-width: 450px; }
    .template.inner .content .main .video video {
      max-width: 100%;
      outline: none; }
    @media (max-width: 1100px) {
      .template.inner .content .main .text {
        max-width: none;
        min-width: 0;
        padding-right: 0; }
      .template.inner .content .main .image, .template.inner .content .main .video, .template.inner .content .main .blog-links {
        float: right;
        width: 50%;
        min-width: 280px;
        max-width: none;
        padding: 0 0 40px 40px; } }
    @media (max-width: 768px) {
      .template.inner .content .main {
        display: flex;
        flex-direction: column; }
        .template.inner .content .main .image, .template.inner .content .main .video, .template.inner .content .main .blog-links {
          float: none;
          width: 100%;
          min-width: 0;
          padding-left: 0; } }

.template.inner.inner-vodka-space .content .main .text, .template.inner.inner-research-institute .content .main .text {
  max-width: 660px; }

@media (max-width: 1100px) {
  .template.inner.inner-vodka-space .content .main .blog-links, .template.inner.inner-research-institute .content .main .blog-links {
    width: auto; } }

@media (max-width: 768px) {
  .template.inner.inner-vodka-space .content .main, .template.inner.inner-research-institute .content .main {
    flex-direction: column-reverse; }
    .template.inner.inner-vodka-space .content .main .blog-links, .template.inner.inner-research-institute .content .main .blog-links {
      margin-top: 40px;
      padding-top: 20px;
      padding-bottom: 0;
      border-top: solid 1px rgba(110, 112, 112, 0.5); } }

iframe.product-doc {
  width: 100%;
  height: 600px;
  margin-top: 0;
  border: 0; }

@media screen and (max-width: 720px) {
  .template.inner .content .main > .text .protected-content .locked {
    display: block; } }

#container {
  _margin-left: 200px;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding-top: 54px; }
  #container > .section {
    position: relative; }
    #container > .section .navbar {
      position: fixed;
      z-index: 1;
      top: 54px;
      width: 100%;
      background-color: white;
      padding: 0 7vw; }
      @media (max-width: 1100px) {
        #container > .section .navbar {
          padding: 0; } }
      #container > .section .navbar > .inner {
        max-width: 1100px;
        margin: auto; }
        #container > .section .navbar > .inner .menu a {
          display: flex; }
          #container > .section .navbar > .inner .menu a .icon {
            width: 16px;
            margin-right: 7px;
            margin-top: -1px; }
    #container > .section > .main {
      padding: 120px 7vw 90px;
      _min-height: 100vh; }
      #container > .section > .main .template {
        max-width: 1100px;
        margin: auto; }
      @media (max-width: 1100px) {
        #container > .section > .main {
          padding: 145px 7vw 70px; } }

#main-header {
  padding: 0 7vw; }
  #main-header .et_menu_container {
    max-width: 1100px;
    margin: auto; }

.template.grid .items, .template.cards .items, .template.landing .items {
  display: grid;
  grid-gap: 50px 20px;
  margin-top: 50px; }
  @media (max-width: 1100px) {
    .template.grid .items, .template.cards .items, .template.landing .items {
      grid-gap: 30px 20px;
      margin-top: 30px; } }
  .template.grid .items .item, .template.cards .items .item, .template.landing .items .item {
    background-color: white; }
    .template.grid .items .item .text, .template.cards .items .item .text, .template.landing .items .item .text {
      padding: 22px 28px 18px; }
      @media (max-width: 600px) {
        .template.grid .items .item .text, .template.cards .items .item .text, .template.landing .items .item .text {
          padding: 18px 18px 16px; } }
      .template.grid .items .item .text .headline, .template.cards .items .item .text .headline, .template.landing .items .item .text .headline {
        display: flex; }
        .template.grid .items .item .text .headline .icon, .template.cards .items .item .text .headline .icon, .template.landing .items .item .text .headline .icon {
          width: 18px;
          margin-right: 8px;
          margin-top: -1px;
          flex-shrink: 0; }
      .template.grid .items .item .text .details .divider, .template.cards .items .item .text .details .divider, .template.landing .items .item .text .details .divider {
        margin: 0 5px; }
      .template.grid .items .item .text .details .showDate, .template.cards .items .item .text .details .showDate, .template.landing .items .item .text .details .showDate {
        opacity: .6;
        font-size: 12px;
        white-space: nowrap; }
      .template.grid .items .item .text .link, .template.cards .items .item .text .link, .template.landing .items .item .text .link {
        display: block;
        text-decoration: none; }
        .template.grid .items .item .text .link.more, .template.cards .items .item .text .link.more, .template.landing .items .item .text .link.more {
          margin-top: 35px;
          text-align: right; }

.template.grid .items, .template.cards .items {
  grid-template-columns: repeat(3, 1fr); }
  @media (max-width: 450px) {
    .template.grid .items, .template.cards .items {
      grid-template-columns: repeat(1, 1fr); } }
  .template.grid .items .item .image, .template.cards .items .item .image {
    background-color: rgba(73, 148, 200, 0.25); }
    .template.grid .items .item .image img, .template.cards .items .item .image img {
      background-color: white; }
  .template.grid .items .item .text, .template.cards .items .item .text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

@media (max-width: 1100px) {
  .template.grid .items {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 450px) {
  .template.grid .items {
    grid-template-columns: repeat(1, 1fr); } }

.template.grid .items .item {
  display: flex;
  flex-direction: column; }

.template.cards .items {
  grid-template-columns: repeat(2, 1fr); }
  .template.cards .items .item {
    display: flex; }
    .template.cards .items .item .image {
      width: 45%; }
    .template.cards .items .item .text {
      width: 55%; }
      .template.cards .items .item .text .contents p {
        margin-top: 9px;
        line-height: 1.5; }
      .template.cards .items .item .text .link.more {
        margin-top: 10px; }
  @media (max-width: 1100px) {
    .template.cards .items {
      grid-template-columns: repeat(1, 1fr); }
      .template.cards .items .item .image {
        width: 180px;
        flex-shrink: 0; }
      .template.cards .items .item .text {
        width: auto; } }
  @media (max-width: 600px) {
    .template.cards .items .item .image {
      width: 45%; }
    .template.cards .items .item .text {
      width: 55%; } }
  @media (max-width: 450px) {
    .template.cards .items .item {
      display: block; }
      .template.cards .items .item .image {
        width: 100%; }
      .template.cards .items .item .text {
        width: 100%; }
        .template.cards .items .item .text .link.more {
          margin-top: 35px; } }

.template.landing .items {
  grid-template-columns: repeat(2, 1fr); }
  .template.landing .items .item {
    text-align: center;
    display: flex;
    justify-content: center; }
    .template.landing .items .item .text {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .template.landing .items .item .text h4 {
        display: inline-block;
        position: relative; }
        .template.landing .items .item .text h4 .icon {
          position: absolute;
          left: -26px;
          width: 17px; }
      .template.landing .items .item .text .link.more {
        text-align: center; }
  @media (max-width: 600px) {
    .template.landing .items {
      margin-top: 30px;
      grid-gap: 30px 20px; }
      .template.landing .items .item {
        grid-column: span 2; } }

.template.table .items {
  width: 100%;
  background-color: white; }
  .template.table .items .item {
    display: flex;
    justify-content: space-between;
    padding: 10px 18px;
    font-size: 14px; }
    .template.table .items .item .icon {
      width: 18px;
      margin-right: 8px;
      margin-top: -3px; }
    .template.table .items .item .two, .template.table .items .item .three, .template.table .items .item > .link {
      margin-left: 15px; }
    .template.table .items .item > .link {
      white-space: nowrap; }
    .template.table .items .item .spacer {
      flex-grow: 1; }
    .template.table .items .item:nth-of-type(even) {
      background-color: rgba(141, 189, 133, 0.1); }
    @media (max-width: 600px) {
      .template.table .items .item {
        display: block;
        padding: 10px 13px; }
        .template.table .items .item .two, .template.table .items .item .three, .template.table .items .item > .link {
          margin-left: 0px; }
        .template.table .items .item > .link {
          text-align: right; } }
  @media (max-width: 450px) {
    .template.table .items .item .link.more {
      display: block;
      margin-top: 15px; } }

.is-ie .template.grid .items, .is-ie .template.cards .items, .is-ie .template.landing .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .is-ie .template.grid .items .item, .is-ie .template.cards .items .item, .is-ie .template.landing .items .item {
    flex-basis: 48%;
    margin-bottom: 40px; }

.is-ie .template.grid .items .item {
  flex-basis: 31%;
  display: block; }

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  height: 100vh;
  background-image: url("/images/ui/loader.svg");
  background-size: 60px;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 1s, visibility 1s; }
  .loader.first-load {
    z-index: 11;
    opacity: 1; }
    .loader.first-load.loaded {
      opacity: 0;
      visibility: hidden; }

@font-face {
  font-family: ETmodules;
  src: url(/fonts/etmodules/modules.eot);
  src: url(/fonts/etmodules/modules.eot?#iefix) format("embedded-opentype"), url(/fonts/etmodules/modules.ttf) format("truetype"), url(/fonts/etmodules/modules.woff) format("woff"), url(/fonts/etmodules/modules.svg#ETmodules) format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

nav ul,
nav ol,
nav ul li,
nav ol li {
  margin: 0;
  font-weight: inherit; }

input.et-search-field {
  display: none; }

#et-secondary-nav .menu-item-has-children > a:first-child,
#top-menu .menu-item-has-children > a:first-child {
  padding-right: 20px;
  padding-bottom: 20px; }

#main-header {
  position: fixed; }

#main-header {
  _z-index: 99999;
  z-index: 10;
  top: 0;
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  font-weight: 500;
  line-height: 23px; }

#main-header {
  transition: background-color 0.4s, color 0.4s, transform 0.4s, opacity 0.4s ease-in-out; }

article,
aside,
footer,
header,
hgroup,
nav,
section {
  display: block; }

.container.et_menu_container {
  z-index: 99;
  height: 54px; }

.container {
  position: relative;
  text-align: left; }

.container {
  position: relative;
  margin: auto; }

.logo_container {
  position: absolute;
  width: 100%;
  height: 100%; }

.logo_container {
  transition: all 0.4s ease-in-out; }

span.logo_helper {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle; }

a {
  color: #4994c8;
  text-decoration: none; }

#logo {
  max-height: 45%; }

#logo {
  display: inline-block;
  float: none;
  margin-bottom: 0;
  vertical-align: middle;
  transition: all 0.4s ease-in-out; }

#et-top-navigation {
  float: right;
  font-weight: 600;
  padding-top: 20px; }

#top-menu,
.fullwidth-menu,
nav#top-menu-nav,
nav.fullwidth-menu-nav {
  float: left; }

#top-menu,
#top-menu-nav {
  line-height: 0; }

#top-menu li {
  display: inline-block;
  padding-right: 22px;
  font-size: 14px; }

#et-secondary-menu li,
#top-menu li {
  word-wrap: break-word; }

.nav li {
  position: relative;
  line-height: 1em; }

@media (max-width: 981px) {
  nav > ul > li > a {
    padding-bottom: 20px; } }

#et-secondary-nav .menu-item-has-children > a:first-child:after,
#top-menu .menu-item-has-children > a:first-child:after {
  position: absolute;
  top: 0;
  right: 0;
  font-family: ETmodules;
  font-size: 16px;
  font-weight: 800;
  content: "3"; }

.nav li ul {
  text-align: left; }

.nav li ul {
  visibility: hidden;
  z-index: 9999;
  width: 240px;
  border-top: 3px solid #4994c8;
  opacity: 0;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); }

.nav li ul {
  position: absolute;
  padding: 20px 0; }

.et-search-form,
.et_mobile_menu,
.footer-widget li:before,
.nav li ul,
blockquote {
  border-color: #4994c8; }

#top-menu li li {
  margin: 0;
  padding: 0 20px; }

#top-menu li {
  display: inline-block;
  padding-right: 22px;
  font-size: 14px; }

.nav li li {
  position: relative;
  line-height: 2em; }

.nav li li {
  margin: 0;
  padding: 0 20px; }

.nav li {
  position: relative;
  line-height: 1em; }

#top-menu li li a {
  width: 200px;
  padding: 6px 20px; }

#top-menu a {
  display: block;
  position: relative;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  transition: all 0.4s ease-in-out; }

#top-menu > li:last-child {
  padding-right: 0px; }

#et-info-email:hover,
#et-secondary-menu > ul > li > a:hover,
#top-menu-nav > ul > li > a:hover,
.et-social-icons a:hover {
  opacity: 0.7;
  transition: all 0.4s ease-in-out; }

#top-menu a.active {
  color: #4994c8; }

#top-menu {
  font-family: "Open Sans", Arial, sans-serif; }

.nav ul li a:hover {
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.03); }

#et_mobile_nav_menu {
  display: none; }

@media (max-width: 981px) {
  #top-menu {
    display: none; }
  #et_mobile_nav_menu {
    display: block;
    margin-top: 3px;
    font-weight: 600; }
  .mobile_menu_bar.mobile_menu_bar_toggle {
    top: -8px; }
  .mobile_nav ul,
  .mobile_nav ol {
    list-style: none;
    margin: 0; }
    .mobile_nav ul li,
    .mobile_nav ol li {
      margin: 0;
      font-weight: 600; }
  .mobile_nav .select_page {
    display: none; }
  .mobile_menu_bar:before {
    color: #4994c8; }
  .mobile_menu_bar {
    display: block;
    position: relative;
    line-height: 0; }
  .mobile_menu_bar:before {
    position: relative;
    top: 0;
    left: 0;
    font-size: 32px;
    content: "\61";
    cursor: pointer; }
  .et_mobile_menu {
    visibility: visible;
    position: absolute;
    z-index: 9999;
    left: 0;
    width: 100%;
    padding: 5%;
    border-top: 3px solid #4994c8;
    opacity: 1;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 0; }
  .et_mobile_menu .menu-item-has-children > a {
    background-color: rgba(0, 0, 0, 0.03);
    font-weight: 700; }
  .et_mobile_menu li a {
    display: block;
    padding: 10px 5%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    color: #666; }
  .et_mobile_menu li a,
  .nav li li a {
    font-size: 14px;
    transition: all 0.2s ease-in-out; }
  #main-header .et_mobile_menu li ul,
  .et_pb_fullwidth_menu .et_mobile_menu li ul {
    display: block !important;
    visibility: visible !important;
    padding-left: 10px; }
  .et_mobile_menu li li {
    padding-left: 5%; }
  .et_mobile_menu li a:hover {
    opacity: 0.7;
    background-color: rgba(0, 0, 0, 0.03); }
  #mobile_menu {
    overflow-y: scroll;
    max-height: calc(100vh - 54px);
    padding-bottom: 130px; }
  #mobile_menu,
  #main-header .et_mobile_menu li ul,
  .et_pb_fullwidth_menu .et_mobile_menu li ul {
    opacity: 0;
    visibility: hidden !important;
    transition: opacity 0.3s, visibility 0.3s; }
  body.navIn #mobile_menu,
  body.navIn #main-header .et_mobile_menu li ul,
  body.navIn .et_pb_fullwidth_menu .et_mobile_menu li ul {
    opacity: 1;
    visibility: visible !important; } }

et-info-email:before,
#et-info-phone:before,
#et_search_icon:before,
.comment-reply-link:after,
.et-cart-info span:before,
.et-pb-arrow-next:before,
.et-pb-arrow-prev:before,
.et-social-icon a:before,
.et_audio_container .mejs-playpause-button button:before,
.et_audio_container .mejs-volume-button button:before,
.et_overlay:before,
.et_password_protected_form .et_submit_button:after,
.et_pb_button:after,
.et_pb_contact_reset:after,
.et_pb_contact_submit:after,
.et_pb_font_icon:before,
.et_pb_newsletter_button:after,
.et_pb_pricing_table_button:after,
.et_pb_promo_button:after,
.et_pb_social_icon a.icon:before,
.et_pb_testimonial:before,
.et_pb_toggle_title:before,
.form-submit .et_pb_button:after,
.mobile_menu_bar:before,
.woocommerce #content input.button.alt:after,
.woocommerce #content input.button:after,
.woocommerce #respond input#submit.alt:after,
.woocommerce #respond input#submit:after,
.woocommerce a.button.alt:after,
.woocommerce button.button.alt:after,
.woocommerce button.button:after,
.woocommerce button.single_add_to_cart_button.button:after,
.woocommerce input.button.alt:after,
.woocommerce input.button:after,
.woocommerce-page #content input.button.alt:after,
.woocommerce-page #content input.button:after,
.woocommerce-page #respond input#submit.alt:after,
.woocommerce-page #respond input#submit:after,
.woocommerce-page a.button.alt:after,
.woocommerce-page a.button:after,
.woocommerce-page button.button.alt:after,
.woocommerce-page button.button:after,
.woocommerce-page input.button.alt:after,
.woocommerce-page input.button:after,
a.et_pb_more_button:after {
  text-shadow: 0 0;
  font-family: ETmodules !important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  text-transform: none;
  speak: none; }

.nav li li ul {
  z-index: 1000;
  top: -23px;
  left: 240px; }

.nav li.et-touch-hover > ul,
.nav li:hover > ul {
  visibility: visible;
  opacity: 1; }

.nav li ul {
  transition: all 0.2s; }

#top-menu > li > ul > li > a {
  padding-bottom: 6px !important; }

#top-menu > li > ul > li > a::after {
  top: 6px !important; }

.et_mobile_menu li .menu-item-has-children > a {
  background-color: transparent; }

#container > .section .navbar {
  border-bottom: solid 1px #f0f0f0; }
  #container > .section .navbar .inner {
    background-color: white;
    display: flex;
    justify-content: space-between; }
    #container > .section .navbar .inner .menu {
      display: flex; }
      #container > .section .navbar .inner .menu a {
        color: #4994c8;
        font-weight: 700;
        font-size: 14px;
        padding: 17px 30px 12px;
        text-decoration: none;
        border-bottom: solid 5px transparent;
        transition: border-color .3s; }

#container > .section .status {
  font-size: 14px;
  color: #6e7070;
  padding: 16px 0px 13px 30px; }
  #container > .section .status button {
    margin-left: 8px; }
  #container > .section .status .circle {
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: grey;
    margin-right: 8px; }
  #container > .section .status.mobile {
    display: none; }

@media (max-width: 1100px) {
  #container > .section .navbar {
    padding-top: 0;
    background-color: #333333;
    _transition: padding .5s; }
    #container > .section .navbar .inner {
      position: relative;
      display: block; }
      #container > .section .navbar .inner .menu a {
        justify-content: center;
        width: 25%;
        font-size: 13px;
        text-align: center;
        padding: 13px 0 10px;
        border-left: solid 1px #f0f0f0; } }
      @media (max-width: 1100px) and (max-width: 450px) {
        #container > .section .navbar .inner .menu a .icon {
          width: 12px;
          margin-right: 4px;
          margin-top: 1px; } }

@media (max-width: 1100px) {
        #container > .section .navbar .inner .menu a:first-of-type {
          border-left: 0; }
        #container > .section .navbar .inner .menu a.active {
          border-bottom-width: 4px; } }
    @media (max-width: 1100px) and (max-width: 370px) {
      #container > .section .navbar .inner .menu a {
        font-size: 12px; }
        #container > .section .navbar .inner .menu a .icon {
          margin-right: 1px; } }

@media (max-width: 1100px) {
  #container > .section .status.desktop {
    display: none; }
  #container > .section .status.mobile {
    display: block;
    position: absolute;
    top: 46px;
    right: 0;
    padding-right: 34px;
    font-size: 12px; }
    #container > .section .status.mobile .link {
      font-size: 12px; }
  #mobileThumb {
    transition: transform .5s; }
  body.menu-up #container > .section .navbar {
    padding-top: 0; }
  body.menu-up #mobileThumb {
    transform: translateY(-43px); } }

#login-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11; }
  #login-overlay .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); }
  #login-overlay .scroll-wrap {
    overflow-y: auto;
    display: flex;
    height: 100%;
    padding: 7vw; }
    #login-overlay .scroll-wrap .center-wrap {
      margin-top: auto;
      margin-bottom: auto;
      width: 100%; }
      #login-overlay .scroll-wrap .center-wrap .content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
        #login-overlay .scroll-wrap .center-wrap .content .form-holder {
          position: relative;
          z-index: 1;
          width: 380px;
          padding: 40px;
          background-color: white;
          padding: 35px; }
          #login-overlay .scroll-wrap .center-wrap .content .form-holder form {
            margin-top: 25px; }
          #login-overlay .scroll-wrap .center-wrap .content .form-holder.login {
            width: 760px;
            max-width: 100%;
            display: flex; }
            #login-overlay .scroll-wrap .center-wrap .content .form-holder.login > div {
              width: 50%;
              flex-grow: 0;
              flex-shrink: 0; }
            #login-overlay .scroll-wrap .center-wrap .content .form-holder.login .new-account {
              padding-right: 50px; }
              #login-overlay .scroll-wrap .center-wrap .content .form-holder.login .new-account h4 + p {
                margin-top: 22px; }
            #login-overlay .scroll-wrap .center-wrap .content .form-holder.login .log-in {
              padding-left: 50px;
              border-left: solid 1px rgba(110, 112, 112, 0.5); }
            @media (max-width: 1100px) {
              #login-overlay .scroll-wrap .center-wrap .content .form-holder.login {
                width: 380px;
                flex-direction: column-reverse; }
                #login-overlay .scroll-wrap .center-wrap .content .form-holder.login > div {
                  width: 100%; }
                #login-overlay .scroll-wrap .center-wrap .content .form-holder.login .log-in {
                  padding-bottom: 30px;
                  padding-left: 0;
                  border-left: none;
                  border-bottom: solid 1px rgba(110, 112, 112, 0.5); }
                #login-overlay .scroll-wrap .center-wrap .content .form-holder.login .new-account {
                  padding-top: 30px;
                  padding-right: 0; } }

@media (max-width: 768px) {
  #login-overlay .scroll-wrap {
    padding-left: 7vw; }
    #login-overlay .scroll-wrap .center-wrap .content .form-holder {
      padding: 30px; } }

form input, form textarea, form button {
  -webkit-appearance: none;
  border-radius: 0; }

form input *:first-of-type {
  margin-top: 0; }

form input[type=text], form input[type=email], form input[type=password], form input[type=textarea] {
  display: block;
  width: 100%;
  margin-top: 18px;
  border: solid 1px grey;
  font-size: 16px;
  line-height: 1.5;
  padding: 10px 10px; }

form input:disabled {
  cursor: not-allowed !important;
  opacity: .4; }

form .note {
  padding: 15px;
  margin-top: 18px;
  background-color: #f0f0f0; }
  form .note p {
    font-size: 14px;
    line-height: 1.5;
    color: #4661ad;
    margin-top: 0; }

form input[type=submit] {
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: grey;
  font-size: 15px;
  font-weight: 700;
  color: white;
  padding: 10px 15px;
  line-height: 1.5; }
  form input[type=submit].link {
    background-color: transparent;
    display: inline;
    padding: 0;
    text-decoration: underline;
    color: #4661ad;
    font-weight: 700; }
    form input[type=submit].link.smaller {
      font-size: 14px;
      font-weight: 400;
      text-decoration: none; }

.fieldRow {
  display: flex;
  justify-content: space-between; }
  .fieldRow:first-child input {
    margin-top: 0; }
  .fieldRow input {
    width: calc(50% - 10px) !important; }

.input-wrap {
  position: relative; }
  .input-wrap .button, .input-wrap button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%; }

.button:disabled, button:disabled {
  cursor: not-allowed !important;
  opacity: .4;
  transition: opacity .15s; }

.thankyou {
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s;
  _transition-duration: 0s;
  font-size: .85em;
  margin: 0;
  margin-top: .5em; }
  .thankyou.show {
    opacity: 1;
    visibility: visible;
    transition-duration: .3s; }

.button, button, form input[type=submit] {
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: grey;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  padding: 10px 15px;
  line-height: 1.5; }

.button.link, button.link, form input[type=submit].link {
  background-color: transparent;
  display: inline;
  padding: 0;
  text-decoration: underline;
  color: #4661ad;
  font-weight: 700;
  transition: opacity .3s; }

button.link:disabled {
  opacity: .5;
  cursor: default; }

::-webkit-input-placeholder {
  color: grey; }

::-moz-placeholder {
  color: grey; }

:-ms-input-placeholder {
  color: grey; }

::placeholder {
  color: grey; }

.submit-wrap {
  position: relative;
  text-align: right;
  clear: both;
  margin-top: 25px; }
  .submit-wrap .loader {
    position: relative;
    display: inline-block;
    width: 1.8em;
    height: 1.8em;
    margin-right: 1em;
    margin-left: 1em;
    vertical-align: middle;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s; }
    .submit-wrap .loader div {
      width: 100%;
      height: 100%; }
      .submit-wrap .loader div div {
        width: 100%;
        height: 100%; }
        .submit-wrap .loader div div svg {
          width: 100%;
          height: 100%; }
  .submit-wrap.loading .loader {
    opacity: .7;
    visibility: visible; }
  .submit-wrap.full {
    margin-top: 1em;
    position: relative; }
    .submit-wrap.full .loader {
      position: absolute;
      right: 1em;
      top: 0;
      bottom: 0;
      margin: auto; }
  .submit-wrap input {
    display: inline-block !important;
    float: none !important;
    margin: 0 !important;
    vertical-align: middle; }
    .submit-wrap input:active {
      top: 0 !important; }
  .submit-wrap.footer {
    position: relative;
    float: right; }
    .submit-wrap.footer .loader {
      position: absolute;
      top: 0;
      right: 5px;
      width: 1.2em;
      pointer-events: none; }
    .submit-wrap.footer input {
      position: absolute;
      top: 0;
      right: 0; }

.close-x {
  display: block;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
  width: 12px;
  height: 12px;
  opacity: 1;
  transition: opacity .3s; }
  .close-x:hover {
    _opacity: .7; }
  .close-x:before, .close-x:after {
    position: absolute;
    left: 5px;
    content: ' ';
    height: 12px;
    width: 2px;
    background-color: grey; }
  .close-x:before {
    transform: rotate(45deg); }
  .close-x:after {
    transform: rotate(-45deg); }

/* user */
#main-header {
  opacity: 1; }

.oops {
  min-height: calc(100vh - 55px);
  display: flex;
  justify-content: center;
  align-items: center; }
  .oops h1 {
    font-size: 32px;
    color: #4460AC;
    padding-bottom: 16vh;
    width: 760px;
    text-align: center; }
    .oops h1 p {
      margin-top: -30px;
      font-size: 22px; }
    .oops h1 ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 14px;
      text-align: center; }
      .oops h1 ul li {
        display: inline-block;
        width: 260px;
        height: 65px;
        text-align: center;
        margin: 40px; }
        .oops h1 ul li a {
          width: 260px;
          height: 65px;
          line-height: 65px;
          text-align: center;
          display: block;
          font-weight: 700;
          font-size: 26px;
          color: #4460ac;
          background: #fff;
          box-shadow: 0px 0px 10px #878787; }
          .oops h1 ul li a.t {
            background: #4460ac;
            color: #fff;
            font-weight: 400; }

@media screen and (max-width: 720px) {
  .oops h1 img {
    width: 70%;
    display: block;
    margin: 0 auto; }
  .oops h1 p {
    margin-top: 0px;
    padding: 0 20px;
    padding-bottom: 30px;
    font-size: 16px; }
  .oops h1 ul {
    padding: 0 20px; }
    .oops h1 ul li {
      width: 60%;
      height: auto;
      margin: 0 0 20px 0;
      line-height: initial; }
      .oops h1 ul li a {
        display: block;
        width: 100%;
        height: auto;
        line-height: initial;
        font-size: 16px;
        padding: 16px 0; } }

.main.user {
  opacity: 1;
  min-height: calc(100vh - 55px);
  background: url("/static/img/use_bg.svg") no-repeat center bottom;
  background-size: cover; }
  .main.user .loading {
    display: none !important; }
  .main.user .oops h1 {
    color: #fff; }
  .main.user h4 {
    margin: 0;
    font-weight: 700;
    font-style: normal;
    font-size: 40px;
    color: #FFFFFF;
    position: relative; }
    .main.user h4.header {
      padding-top: 3%; }
    .main.user h4 a {
      position: absolute;
      right: 0;
      bottom: 6px;
      font-weight: 350;
      font-style: normal;
      font-size: 16px;
      letter-spacing: 1px;
      color: #FFFF00; }
  .main.user .new-account {
    zoom: 0.9;
    min-height: calc(100vh - 55px);
    display: flex;
    justify-content: center;
    align-items: center; }
    .main.user .new-account .warp {
      width: 1080px;
      margin-bottom: 10vh; }
  .main.user form {
    margin-top: 10px;
    background: #fff;
    border-radius: 10px; }
    .main.user form .stepBar {
      padding: 20px;
      padding-bottom: 40px;
      background: #7e8ec6;
      border-radius: 10px; }
      .main.user form .stepBar[data-step=step1] ul li:nth-child(1) {
        color: #4460AC;
        background: #fff; }
        .main.user form .stepBar[data-step=step1] ul li:nth-child(1) span {
          display: block; }
      .main.user form .stepBar[data-step=step2] ul li:nth-child(2) {
        color: #4460AC;
        background: #fff; }
        .main.user form .stepBar[data-step=step2] ul li:nth-child(2) span {
          display: block; }
      .main.user form .stepBar[data-step=step3] ul li:nth-child(3) {
        color: #4460AC;
        background: #fff; }
        .main.user form .stepBar[data-step=step3] ul li:nth-child(3) span {
          display: block; }
      .main.user form .stepBar ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto;
        width: 473px;
        height: 73px;
        list-style: none;
        background: url("/static/img/use_stepbar_bg.svg") no-repeat 0 0; }
        .main.user form .stepBar ul li {
          position: relative;
          margin: 0;
          width: 45px;
          border-radius: 50%;
          margin: 14px;
          text-align: center;
          line-height: 43px;
          color: #fff;
          font-size: 20px;
          font-weight: 700; }
          .main.user form .stepBar ul li span {
            display: none;
            position: absolute;
            left: -26px;
            bottom: -50px;
            font-weight: 350;
            font-style: normal;
            font-size: 15px;
            color: #FFFFFF;
            width: 100px;
            text-align: center; }
          .main.user form .stepBar ul li.active {
            color: #4460AC;
            background: #fff; }
            .main.user form .stepBar ul li.active span {
              display: block; }
    .main.user form .stepBox {
      padding: 80px 0 50px 0; }
      .main.user form .stepBox.success {
        padding: 30px 0; }
        .main.user form .stepBox.success h3 {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 10px; }
        .main.user form .stepBox.success h4 {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center; }
          .main.user form .stepBox.success h4 p {
            margin: 0;
            position: absolute;
            left: 62%;
            color: #4661ad; }
        .main.user form .stepBox.success ul {
          margin: 0;
          padding: 20px 0;
          list-style: none;
          display: flex;
          justify-content: center;
          align-items: center; }
          .main.user form .stepBox.success ul li {
            padding: 0 60px; }
            .main.user form .stepBox.success ul li a {
              display: block;
              width: 216px;
              text-align: center;
              padding: 6px 0;
              background-color: #4460ac;
              border-radius: 30px;
              font-size: 23px;
              color: #fff; }
    .main.user form dl {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 30px; }
      .main.user form dl.t {
        padding-bottom: 16px; }
        .main.user form dl.t dd {
          position: relative; }
          .main.user form dl.t dd button {
            position: absolute;
            right: 0;
            top: 0;
            background: #4460ac;
            padding: 9px 20px;
            font-size: 18px; }
            .main.user form dl.t dd button[disabled] {
              background: #d7d7d7;
              color: #555; }
      .main.user form dl dt {
        font-weight: 500;
        font-style: normal;
        font-size: 23px;
        text-align: right;
        width: 150px;
        padding-right: 10px; }
        .main.user form dl dt span {
          color: #D9001B; }
      .main.user form dl dd {
        position: relative;
        width: 477px;
        margin-right: 50px; }
        .main.user form dl dd .error {
          position: absolute;
          left: 190px;
          top: 20px;
          font-size: 12px;
          color: #D9001B;
          padding-left: 20px; }
        .main.user form dl dd input {
          margin: 0;
          width: 477px;
          height: 46px;
          padding: 3px 2px 3px 10px;
          font-family: 'Arial Normal', 'Arial';
          font-weight: 400;
          font-style: normal;
          font-size: 16px;
          letter-spacing: normal;
          color: #AAAAAA;
          vertical-align: none;
          text-align: left;
          text-transform: none;
          border: 1px solid #000; }
          .main.user form dl dd input[type=submit] {
            width: auto;
            height: auto;
            border: none;
            background: #4460ac;
            color: #fff;
            padding: 8px 70px;
            font-size: 18px; }
        .main.user form dl dd button {
          background: #4460ac;
          padding: 8px 70px;
          font-size: 18px; }
        .main.user form dl dd .error-holder p {
          margin: 0;
          padding-bottom: 16px;
          color: rgba(217, 0, 27, 0.996078); }
          .main.user form dl dd .error-holder p.success {
            display: block;
            color: #333; }
          .main.user form dl dd .error-holder p.link {
            display: block;
            color: #333; }
    .main.user form .notice {
      margin: 0;
      text-align: center;
      font-size: 12px;
      padding-bottom: 20px; }
  .main.user .login {
    padding-top: 180px;
    min-height: calc(100vh - 55px);
    background: url("/static/img/use_bg2.svg") no-repeat center bottom;
    justify-content: center;
    align-items: center; }
    .main.user .login .success {
      display: none; }
    .main.user .login[data-success=success] {
      background: none; }
      .main.user .login[data-success=success] .use_login_body,
      .main.user .login[data-success=success] .use_login_hand {
        display: none; }
      .main.user .login[data-success=success] .log-in {
        display: none; }
      .main.user .login[data-success=success] .success {
        display: block;
        width: 450px;
        margin: 0 auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        padding: 50px 0 30px 0;
        margin-bottom: 55px;
        position: relative; }
        .main.user .login[data-success=success] .success h3 {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 10px;
          position: relative;
          z-index: 1; }
        .main.user .login[data-success=success] .success .use_login_half {
          position: absolute;
          right: -99px;
          top: 0;
          z-index: 0; }
        .main.user .login[data-success=success] .success h4 {
          text-align: center; }
          .main.user .login[data-success=success] .success h4 img {
            margin: 0 auto; }
          .main.user .login[data-success=success] .success h4 p {
            margin: 0;
            color: #4661ad; }
        .main.user .login[data-success=success] .success ul {
          margin: 0;
          padding: 20px 0 0 0;
          list-style: none;
          text-align: right; }
          .main.user .login[data-success=success] .success ul li {
            padding: 0 20px; }
            .main.user .login[data-success=success] .success ul li a {
              text-align: center;
              padding: 8px 18px;
              background-color: #4460ac;
              border-radius: 5px;
              font-size: 18px;
              color: #fff; }
    .main.user .login .log-in {
      zoom: 0.9;
      width: 450px;
      margin: 0 auto;
      padding: 30px;
      padding-top: 0;
      background: #fff;
      border-radius: 10px;
      position: relative; }
      .main.user .login .log-in .error-holder p {
        color: rgba(217, 0, 27, 0.996078); }
        .main.user .login .log-in .error-holder p.success {
          display: block;
          color: #333; }
        .main.user .login .log-in .error-holder p.link {
          display: block;
          color: #333; }
      .main.user .login .log-in h4 {
        font-weight: 700;
        font-style: normal;
        font-size: 25px;
        color: #4460AC;
        padding-bottom: 16px;
        padding-top: 50px;
        position: relative;
        z-index: 2;
        background: #fff; }
        .main.user .login .log-in h4 span {
          font-weight: 700;
          font-style: normal;
          font-size: 12px;
          color: #AAAAAA; }
      .main.user .login .log-in .use_login_body {
        position: absolute;
        left: 50%;
        top: -170px;
        margin-left: -91px;
        z-index: 1; }
      .main.user .login .log-in .use_login_hand {
        position: absolute;
        left: 50%;
        top: -50px;
        margin-left: -92px;
        z-index: 3; }
      .main.user .login .log-in button[type=button] {
        background-color: #4460ac;
        font-size: 18px;
        padding-left: 30px;
        padding-right: 30px; }
      .main.user .login .log-in .submit-wrap .loader {
        display: none; }
      .main.user .login .log-in .submit-wrap input, .main.user .login .log-in .submit-wrap a {
        display: block;
        width: 100%;
        background-color: #4460ac;
        font-size: 20px;
        border-radius: 30px;
        text-align: center;
        padding: 10px 15px; }
      .main.user .login .log-in .submit-wrap a {
        margin-top: 20px;
        border: 1px solid #4460ac;
        background: #fff;
        color: #4460ac; }
  @media screen and (max-width: 720px) {
    .main.user .new-account .warp {
      margin: 0 20px;
      width: calc(100% - 40px); }
    .main.user h4 {
      font-size: 30px; }
    .main.user form .stepBox {
      padding-top: 30px; }
    .main.user form .stepBox {
      padding-top: 30px; }
    .main.user form .stepBar {
      padding: 20px 10px 30px 10px; }
    .main.user form .stepBar ul {
      zoom: 0.8; }
    .main.user form dl {
      padding-bottom: 10px; }
    .main.user form dl dt {
      width: 30vw; }
    .main.user form dl dt {
      padding-right: 0; }
    .main.user form dl dd {
      width: 60vw; }
    .main.user form dl dd input {
      width: 100%; }
    .main.user form dl dt {
      font-size: 16px; }
    .main.user form .notice {
      padding: 20px; }
    .main.user form .stepBox.success ul {
      display: block; }
    .main.user form .stepBox.success h4 {
      display: block;
      text-align: center; }
    .main.user form .stepBox.success h4 img {
      display: inline; }
    .main.user form .stepBox.success h4 p {
      position: initial; }
    .main.user form .stepBox.success ul li a {
      width: 100%; }
    .main.user form dl dd .error {
      position: initial;
      display: block;
      padding: 0;
      padding-bottom: 10px; }
    .main.user .login .log-in {
      margin: 0 20px;
      width: calc(100% - 40px); }
    .main.user .login .log-in .use_login_body {
      zoom: 0.8; }
    .main.user .login .log-in .use_login_hand {
      zoom: 0.8; }
    .main.user .login[data-success=success] .success {
      margin: 0 20px;
      width: calc(100% - 40px); }
    .main.user .login[data-success=success] .success .use_login_half {
      display: none; } }
  @media screen and (max-width: 385px) {
    .main.user form .stepBox {
      padding-top: 20px;
      padding-bottom: 20px; }
    .main.user form .stepBar ul {
      zoom: 0.7; }
    .main.user .login .log-in .use_login_body {
      zoom: 0.7; }
    .main.user .login .log-in .use_login_hand {
      zoom: 0.7; }
    .main.user form dl dd input {
      width: 110%; }
    .main.user form dl.t dd button {
      right: -10%; }
    .main.user form .notice {
      padding-top: 0; }
    .main.user form .stepBox.success {
      padding: 20px 0; }
    .main.user form .stepBox.success ul {
      padding: 0; }
    .main.user form .stepBox.success ul li a {
      font-size: 20px; } }
  @media screen and (max-width: 320px) {
    .main.user form .stepBar {
      padding: 20px 10px; }
    .main.user form .stepBar ul {
      zoom: 0.6; }
    .main.user h4 {
      font-size: 24px; }
      .main.user h4 a {
        font-size: 13px; }
    .main.user form dl dt {
      font-size: 13px; }
    .main.user form dl dd button {
      font-size: 16px;
      padding: 8px 20px; }
    .main.user form dl.t dd button {
      font-size: 14px;
      line-height: 28px; }
    .main.user form .stepBox.success ul li a {
      font-size: 16px; }
    .main.user .login .log-in .use_login_body {
      zoom: 0.6; }
    .main.user .login .log-in .use_login_hand {
      zoom: 0.6; }
    .main.user .login .log-in h4 {
      padding-top: 20px;
      padding-bottom: 0; }
    .main.user .submit-wrap {
      margin-top: 15px; }
    .main.user .login[data-success=success] .success {
      padding: 20px 0; } }

@media screen and (max-width: 320px) {
  .main.user {
    min-height: auto; }
    .main.user .new-account {
      padding-bottom: 50px; } }

/* user */
.zoom2 .top h4 {
  font-size: 20px; }

.zoom2 .top p {
  font-size: 16px; }

.zoom2 .main .body * {
  font-size: 16px; }

.zoom2 .main .image {
  width: 360px !important;
  max-width: 360px !important; }
  .zoom2 .main .image .passport {
    margin-top: 16px;
    background: rgba(73, 148, 200, 0.15);
    padding: 16px; }
    .zoom2 .main .image .passport p {
      font-size: 14px;
      margin: 0;
      padding-bottom: 1em; }
    .zoom2 .main .image .passport button {
      display: block;
      text-align: center;
      width: 100%; }

@media screen and (max-width: 720px) {
  .zoom2 .main .image {
    width: auto !important;
    max-width: initial !important; } }

.main.passport {
  opacity: 1;
  min-height: calc(100vh - 55px);
  background: url("/static/img/use_bg.svg") no-repeat center bottom;
  background-size: cover; }
  .main.passport .loading {
    display: none !important; }
  .main.passport .oops h1 {
    color: #fff; }
  .main.passport h4 {
    margin: 0;
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    color: #4661AD;
    position: relative; }
    .main.passport h4.header {
      padding-top: 3%; }
    .main.passport h4 a {
      position: absolute;
      right: 0;
      bottom: 6px;
      font-weight: 350;
      font-style: normal;
      font-size: 16px;
      letter-spacing: 1px;
      color: #FFFF00; }
  .main.passport .new-account {
    zoom: 1;
    min-height: calc(100vh - 55px);
    display: flex;
    justify-content: center;
    align-items: center; }
    .main.passport .new-account .left {
      padding-right: 70px; }
      .main.passport .new-account .left h2 {
        font-size: 40px;
        font-weight: 700;
        color: #fff;
        line-height: 120%; }
      .main.passport .new-account .left ul {
        margin: 0;
        padding: 2em 0;
        padding-top: 1em; }
        .main.passport .new-account .left ul li {
          padding-left: 1.4em;
          line-height: 120%;
          color: #fff;
          font-size: 20px;
          list-style: none;
          position: relative; }
          .main.passport .new-account .left ul li::before {
            position: absolute;
            left: 4px;
            top: 10px;
            content: '';
            width: 12px;
            height: 12px;
            background: #fff;
            border-radius: 50%; }
      .main.passport .new-account .left .macbook {
        left: -60px;
        position: relative;
        width: 635px;
        height: 360px;
        background: url("/static/img/passport_macbook.svg") no-repeat center top; }
        .main.passport .new-account .left .macbook .swiper-container {
          position: absolute;
          left: 80px;
          top: 19px;
          width: 472px; }
          .main.passport .new-account .left .macbook .swiper-container img {
            width: 472px;
            height: 286px; }
    .main.passport .new-account .reg {
      width: 450px; }
      .main.passport .new-account .reg form {
        padding: 40px 50px; }
    .main.passport .new-account .textR {
      text-align: right; }
      .main.passport .new-account .textR a {
        color: #4460ac; }
  .main.passport form {
    background: #fff;
    border-radius: 10px; }
    .main.passport form .stepBar {
      padding: 20px;
      padding-bottom: 40px;
      background: #7e8ec6;
      border-radius: 10px; }
      .main.passport form .stepBar[data-step=step1] ul li:nth-child(1) {
        color: #4460AC;
        background: #fff; }
        .main.passport form .stepBar[data-step=step1] ul li:nth-child(1) span {
          display: block; }
      .main.passport form .stepBar[data-step=step2] ul li:nth-child(2) {
        color: #4460AC;
        background: #fff; }
        .main.passport form .stepBar[data-step=step2] ul li:nth-child(2) span {
          display: block; }
      .main.passport form .stepBar[data-step=step3] ul li:nth-child(3) {
        color: #4460AC;
        background: #fff; }
        .main.passport form .stepBar[data-step=step3] ul li:nth-child(3) span {
          display: block; }
      .main.passport form .stepBar ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto;
        width: 473px;
        height: 73px;
        list-style: none;
        background: url("/static/img/use_stepbar_bg.svg") no-repeat 0 0; }
        .main.passport form .stepBar ul li {
          position: relative;
          margin: 0;
          width: 45px;
          border-radius: 50%;
          margin: 14px;
          text-align: center;
          line-height: 43px;
          color: #fff;
          font-size: 20px;
          font-weight: 700; }
          .main.passport form .stepBar ul li span {
            display: none;
            position: absolute;
            left: -26px;
            bottom: -50px;
            font-weight: 350;
            font-style: normal;
            font-size: 15px;
            color: #FFFFFF;
            width: 100px;
            text-align: center; }
          .main.passport form .stepBar ul li.active {
            color: #4460AC;
            background: #fff; }
            .main.passport form .stepBar ul li.active span {
              display: block; }
    .main.passport form .stepBox {
      padding: 30px 0 0px 0; }
      .main.passport form .stepBox.success {
        padding: 30px 0; }
        .main.passport form .stepBox.success h3 {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 10px; }
        .main.passport form .stepBox.success h4 {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center; }
          .main.passport form .stepBox.success h4 p {
            margin: 0;
            position: absolute;
            left: 62%;
            color: #4661ad; }
        .main.passport form .stepBox.success ul {
          margin: 0;
          padding: 20px 0;
          list-style: none;
          display: flex;
          justify-content: center;
          align-items: center; }
          .main.passport form .stepBox.success ul li {
            padding: 0 60px; }
            .main.passport form .stepBox.success ul li a {
              display: block;
              width: 216px;
              text-align: center;
              padding: 6px 0;
              background-color: #4460ac;
              border-radius: 30px;
              font-size: 23px;
              color: #fff; }
    .main.passport form dl {
      padding-bottom: 16px; }
      .main.passport form dl.t {
        padding-bottom: 16px; }
        .main.passport form dl.t dd {
          position: relative; }
          .main.passport form dl.t dd button {
            position: absolute;
            right: 0;
            top: 0;
            background: #4460ac;
            padding: 9px 20px;
            font-size: 18px; }
            .main.passport form dl.t dd button[disabled] {
              background: #d7d7d7;
              color: #555; }
      .main.passport form dl dt {
        font-weight: 500;
        font-style: normal;
        font-size: 23px;
        text-align: right;
        width: 150px;
        padding-right: 10px; }
        .main.passport form dl dt span {
          color: #D9001B; }
      .main.passport form dl dd {
        position: relative; }
        .main.passport form dl dd .error {
          position: absolute;
          left: 190px;
          top: 20px;
          font-size: 12px;
          color: #D9001B;
          padding-left: 20px; }
        .main.passport form dl dd input {
          margin: 0;
          height: 46px;
          padding: 3px 2px 3px 10px;
          font-family: 'Arial Normal', 'Arial';
          font-weight: 400;
          font-style: normal;
          font-size: 16px;
          letter-spacing: normal;
          vertical-align: none;
          text-align: left;
          text-transform: none;
          border: 1px solid #000; }
          .main.passport form dl dd input[type=submit] {
            text-align: center;
            width: 100%;
            height: auto;
            border: none;
            background: #4460ac;
            color: #fff;
            padding: 8px 70px;
            font-size: 18px; }
        .main.passport form dl dd button {
          background: #4460ac;
          padding: 8px 70px;
          font-size: 18px; }
        .main.passport form dl dd .error-holder p {
          margin: 0;
          padding-bottom: 16px;
          color: rgba(217, 0, 27, 0.996078); }
          .main.passport form dl dd .error-holder p.success {
            display: block;
            color: #333; }
          .main.passport form dl dd .error-holder p.link {
            display: block;
            color: #333; }
    .main.passport form .notice {
      margin: 0;
      text-align: center;
      font-size: 12px;
      padding-bottom: 20px; }
  .main.passport .login {
    min-height: calc(100vh - 55px);
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .main.passport .login .success {
      display: none; }
    .main.passport .login .left {
      padding-right: 70px; }
      .main.passport .login .left h2 {
        font-size: 40px;
        font-weight: 700;
        color: #fff;
        line-height: 100%; }
      .main.passport .login .left h3 {
        font-size: 24px;
        font-weight: 400;
        color: #fff;
        line-height: 120%; }
      .main.passport .login .left ul {
        margin: 0;
        padding: 2em 0;
        padding-top: 1em; }
        .main.passport .login .left ul li {
          padding-left: 1.4em;
          line-height: 120%;
          color: #fff;
          font-size: 20px;
          list-style: none;
          position: relative; }
          .main.passport .login .left ul li::before {
            position: absolute;
            left: 4px;
            top: 10px;
            content: '';
            width: 12px;
            height: 12px;
            background: #fff;
            border-radius: 50%; }
      .main.passport .login .left .macbook {
        left: -60px;
        position: relative;
        width: 635px;
        height: 360px;
        background: url("/static/img/passport_macbook.svg") no-repeat center top; }
        .main.passport .login .left .macbook .swiper-container {
          position: absolute;
          left: 80px;
          top: 19px;
          width: 472px; }
          .main.passport .login .left .macbook .swiper-container img {
            width: 472px;
            height: 286px; }
    .main.passport .login .log-in {
      zoom: 1;
      width: 450px;
      padding: 40px 50px;
      padding-top: 0;
      background: #fff;
      border-radius: 10px;
      position: relative; }
      .main.passport .login .log-in .error-holder p {
        color: rgba(217, 0, 27, 0.996078); }
        .main.passport .login .log-in .error-holder p.success {
          display: block;
          color: #333; }
        .main.passport .login .log-in .error-holder p.link {
          display: block;
          color: #333; }
      .main.passport .login .log-in h4 {
        font-weight: 700;
        font-style: normal;
        font-size: 25px;
        color: #4460AC;
        padding-bottom: 16px;
        padding-top: 50px;
        position: relative;
        z-index: 2;
        background: #fff; }
        .main.passport .login .log-in h4 span {
          font-weight: 700;
          font-style: normal;
          font-size: 12px;
          color: #AAAAAA; }
      .main.passport .login .log-in .use_login_body {
        position: absolute;
        left: 50%;
        top: -170px;
        margin-left: -91px;
        z-index: 1; }
      .main.passport .login .log-in .use_login_hand {
        position: absolute;
        left: 50%;
        top: -50px;
        margin-left: -92px;
        z-index: 3; }
      .main.passport .login .log-in button[type=button] {
        background-color: #4460ac;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px; }
      .main.passport .login .log-in .submit-wrap .loader {
        display: none; }
      .main.passport .login .log-in .submit-wrap input {
        display: block;
        width: 100%;
        background-color: #4460ac;
        font-size: 20px;
        border-radius: 0px;
        text-align: center;
        padding: 10px 15px; }
      .main.passport .login .log-in .submit-wrap a {
        display: inline-block;
        margin-top: 20px;
        background: #fff;
        color: #4460ac; }
      .main.passport .login .log-in .netis_wechat {
        padding: 30px 0;
        padding-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .main.passport .login .log-in .netis_wechat img {
          width: 130px;
          flex-shrink: 0; }
        .main.passport .login .log-in .netis_wechat span {
          padding: 0 30px;
          font-size: 13px;
          color: #4661AD; }
  @media screen and (max-width: 720px) {
    .main.passport .new-account .warp {
      margin: 0 20px;
      width: calc(100% - 40px); }
    .main.passport h4 {
      font-size: 24px; }
    .main.passport form .stepBox {
      padding-top: 30px; }
    .main.passport form .stepBox {
      padding-top: 30px; }
    .main.passport form .stepBar {
      padding: 20px 10px 30px 10px; }
    .main.passport form .stepBar ul {
      zoom: 0.8; }
    .main.passport form dl {
      padding-bottom: 10px; }
    .main.passport form dl dt {
      width: 30vw; }
    .main.passport form dl dt {
      padding-right: 0; }
    .main.passport form dl dd {
      width: 60vw; }
    .main.passport form dl dd input {
      width: 100%; }
    .main.passport form dl dt {
      font-size: 16px; }
    .main.passport form .notice {
      padding: 20px; }
    .main.passport form .stepBox.success ul {
      display: block; }
    .main.passport form .stepBox.success h4 {
      display: block;
      text-align: center; }
    .main.passport form .stepBox.success h4 img {
      display: inline; }
    .main.passport form .stepBox.success h4 p {
      position: initial; }
    .main.passport form .stepBox.success ul li a {
      width: 100%; }
    .main.passport form dl dd .error {
      position: initial;
      display: block;
      padding: 0;
      padding-bottom: 10px; }
    .main.passport .login .log-in {
      margin: 0 20px;
      width: calc(100% - 40px); }
    .main.passport .login .log-in .use_login_body {
      zoom: 0.8; }
    .main.passport .login .log-in .use_login_hand {
      zoom: 0.8; }
    .main.passport .login[data-success=success] .success {
      margin: 0 20px;
      width: calc(100% - 40px); }
    .main.passport .login[data-success=success] .success .use_login_half {
      display: none; }
    .main.passport .login {
      padding: 20px 0px; }
      .main.passport .login .left {
        display: none; }
      .main.passport .login .log-in {
        padding: 20px 20px; }
        .main.passport .login .log-in h4 {
          padding-top: 0; }
        .main.passport .login .log-in .netis_wechat {
          padding-top: 10px; }
    .main.passport .new-account {
      padding: 20px 0px; }
      .main.passport .new-account .left {
        display: none; }
      .main.passport .new-account .reg form {
        margin: 0 auto;
        width: calc(100% - 40px);
        padding: 20px 20px; }
        .main.passport .new-account .reg form h4 {
          padding-top: 0; }
        .main.passport .new-account .reg form dl dd {
          width: 100%; } }
  @media screen and (max-width: 385px) {
    .main.passport form .stepBox {
      padding-top: 20px;
      padding-bottom: 20px; }
    .main.passport form .stepBar ul {
      zoom: 0.7; }
    .main.passport .login .log-in .use_login_body {
      zoom: 0.7; }
    .main.passport .login .log-in .use_login_hand {
      zoom: 0.7; }
    .main.passport form dl dd input {
      width: 100%; }
    .main.passport form dl.t dd button {
      right: -0%; }
    .main.passport form .notice {
      padding-top: 0; }
    .main.passport form .stepBox.success {
      padding: 20px 0; }
    .main.passport form .stepBox.success ul {
      padding: 0; }
    .main.passport form .stepBox.success ul li a {
      font-size: 20px; } }
  @media screen and (max-width: 320px) {
    .main.passport form .stepBar {
      padding: 20px 10px; }
    .main.passport form .stepBar ul {
      zoom: 0.6; }
    .main.passport h4 {
      font-size: 24px; }
      .main.passport h4 a {
        font-size: 13px; }
    .main.passport form dl dt {
      font-size: 13px; }
    .main.passport form dl dd button {
      font-size: 16px;
      padding: 8px 20px; }
    .main.passport form dl.t dd button {
      font-size: 14px;
      line-height: 28px; }
    .main.passport form .stepBox.success ul li a {
      font-size: 16px; }
    .main.passport .login .log-in .use_login_body {
      zoom: 0.6; }
    .main.passport .login .log-in .use_login_hand {
      zoom: 0.6; }
    .main.passport .login .log-in h4 {
      padding-top: 20px;
      padding-bottom: 0; }
    .main.passport .submit-wrap {
      margin-top: 15px; }
    .main.passport .login[data-success=success] .success {
      padding: 20px 0; }
    .main.passport .login .log-in .netis_wechat {
      display: none; } }

.cookies_policy {
  transition: all 0.3s ease-in-out;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  opacity: 0; }
  .cookies_policy.open {
    opacity: 1; }
  .cookies_policy.close {
    z-index: -1; }
  .cookies_policy .wrapper {
    width: 782px;
    padding: 0 72px 72px 72px;
    background: #fff;
    left: 50%;
    margin-left: -391px;
    top: 20%;
    position: absolute;
    border-radius: 10px; }
    .cookies_policy .wrapper h2 {
      font-size: 30px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0;
      width: 100%;
      text-align: left;
      margin-top: 64px;
      padding-bottom: 24px;
      border-bottom: 1px solid #E9ECF0; }
    .cookies_policy .wrapper p {
      font-size: 14px;
      color: #5F6464;
      letter-spacing: 0;
      line-height: 25px;
      padding-top: 24px;
      margin-bottom: 82px; }
      .cookies_policy .wrapper p a {
        color: #4460ac; }
    .cookies_policy .wrapper .btns {
      height: 40px; }
      .cookies_policy .wrapper .btns div {
        float: right;
        width: 200px;
        height: 40px;
        margin-left: 16px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        color: #2A2E2E;
        background: #F2F3F5;
        cursor: pointer; }
        .cookies_policy .wrapper .btns div.btn_accept {
          background: #4460ac;
          color: #fff; }
  @media screen and (max-width: 720px) {
    .cookies_policy .wrapper {
      position: initial;
      margin: 0 16px;
      margin-top: 30%;
      width: calc(100vw - 32px);
      padding: 16px; }
      .cookies_policy .wrapper h2 {
        font-size: 22px;
        margin-top: 24px;
        padding-bottom: 14px; }
      .cookies_policy .wrapper p {
        padding-top: 14px;
        margin-bottom: 22px; }
      .cookies_policy .wrapper .btns {
        height: auto; }
        .cookies_policy .wrapper .btns div {
          float: none;
          width: 100%;
          margin: 0;
          margin-bottom: 14px; } }
  @media screen and (max-width: 320px) {
    .cookies_policy .wrapper {
      margin-top: 70px; }
      .cookies_policy .wrapper h2 {
        font-size: 22px;
        margin-top: 0px;
        padding-bottom: 14px; }
      .cookies_policy .wrapper p {
        padding-top: 0px;
        margin-bottom: 22px; } }

/* home primary */
.primary {
  /*
	position:relative;
	min-height:500px;
	padding-right: 310px;
	margin-bottom:25px;
	*/
  display: flex;
  margin-top: 15px;
  min-height: 500px; }

.primary .content {
  /*
	min-height:500px;
	*/
  padding: 20px;
  margin-right: 15px;
  flex-grow: 1;
  width: 795px; }

.primary .side {
  /*
	position:absolute;
	top:0px;
	right:0px;
	width:290px;
	*/
  flex-basis: 290px; }

/* summary */
.summary {
  margin-bottom: 15px;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: #e5e5e5 solid 1px; }

.summary .title {
  color: #4994c8;
  font-weight: bold; }

/* download */
.download {
  margin-bottom: 15px; }

.download .link {
  color: #4994c8;
  font-weight: bold; }

/* sp */
.account {
  font-size: 14px;
  color: #6e7070; }

.account .circle {
  background-color: #4994c8;
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 8px; }

.account .link {
  color: #4994c8; }

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%; }

.input-group-append {
  margin-left: -1px; }

.input-group-append, .input-group-prepend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.form-control {
  display: block;
  /* width: 100%; */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.form-control::-ms-expand {
  background-color: transparent;
  border: 0; }

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::placeholder {
  color: #6c757d;
  opacity: 1; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

/* patch */
.form-holder button:not(.link) {
  background-color: #4994c8; }

.submit-wrap input[type=submit] {
  background-color: #4994c8; }

/* forbidden if have not login
/* forbidden */
.forbidden {
  display: inline-block;
  max-width: 560px;
  min-width: 350px;
  border: solid 1px #4994c8;
  background-color: rgba(73, 148, 200, 0.15);
  padding: 16px; }

.forbidden p {
  margin-top: 0px;
  color: #6e7070;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8; }

.forbidden button {
  background-color: transparent;
  display: inline;
  padding: 0;
  text-decoration: underline;
  color: #4661ad;
  font-weight: 700;
  transition: opacity .3s;
  font-size: 15px; }
