@font-face {
  font-family: ETmodules;
  src: url(/fonts/etmodules/modules.eot);
  src: url(/fonts/etmodules/modules.eot?#iefix) format("embedded-opentype"),
    url(/fonts/etmodules/modules.ttf) format("truetype"),
    url(/fonts/etmodules/modules.woff) format("woff"),
    url(/fonts/etmodules/modules.svg#ETmodules) format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
nav {
  ul,
  ol,
  ul li,
  ol li {
    margin: 0;
    font-weight: inherit;
  }
}

input.et-search-field {
  display: none;
}
#et-secondary-nav .menu-item-has-children > a:first-child,
#top-menu .menu-item-has-children > a:first-child {
  padding-right: 20px;
  padding-bottom: 20px;
}

#main-header {
  // height:54px;
}

#main-header {
  position: fixed;
}
#main-header {
  _z-index: 99999;
  z-index: 10;
  top: 0;
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  font-weight: 500;
  line-height: 23px;
}
#main-header {
  transition: background-color 0.4s, color 0.4s, transform 0.4s,
    opacity 0.4s ease-in-out;
}

article,
aside,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
.container.et_menu_container {
  z-index: 99;
  height: 54px;
}

.container {
  position: relative;
  text-align: left;
}
.container {
  position: relative;
  // width: 80%;
  // max-width: 1100px;
  margin: auto;
}
.logo_container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.logo_container {
  transition: all 0.4s ease-in-out;
}
span.logo_helper {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}
a {
  color: $blue-2;
  text-decoration: none;
}
#logo {
  max-height: 45%;
}
#logo {
  display: inline-block;
  float: none;
  // max-height: 54%;
  margin-bottom: 0;
  vertical-align: middle;
  transition: all 0.4s ease-in-out;
}

#et-top-navigation {
  float: right;
  font-weight: 600;
  padding-top: 20px;
}

#top-menu,
.fullwidth-menu,
nav#top-menu-nav,
nav.fullwidth-menu-nav {
  float: left;
}
#top-menu,
#top-menu-nav {
  line-height: 0;
}
#top-menu li {
  display: inline-block;
  padding-right: 22px;
  font-size: 14px;
}
#et-secondary-menu li,
#top-menu li {
  word-wrap: break-word;
}
.nav li {
  position: relative;
  line-height: 1em;
}
@include for-divi-mobile {
  nav > ul > li > a {
    padding-bottom: 20px;
  }
}
#et-secondary-nav .menu-item-has-children > a:first-child:after,
#top-menu .menu-item-has-children > a:first-child:after {
  position: absolute;
  top: 0;
  right: 0;
  font-family: ETmodules;
  font-size: 16px;
  font-weight: 800;
  content: "3";
}
.nav li ul {
  text-align: left;
}
.nav li ul {
  visibility: hidden;
  z-index: 9999;
  width: 240px;
  border-top: 3px solid $blue-2;
  opacity: 0;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.nav li ul {
  position: absolute;
  padding: 20px 0;
}
.et-search-form,
.et_mobile_menu,
.footer-widget li:before,
.nav li ul,
blockquote {
  border-color: $blue-2;
}
#top-menu li li {
  margin: 0;
  padding: 0 20px;
}
#top-menu li {
  display: inline-block;
  padding-right: 22px;
  font-size: 14px;
}
.nav li li {
  position: relative;
  line-height: 2em;
}
.nav li li {
  margin: 0;
  padding: 0 20px;
}
.nav li {
  position: relative;
  line-height: 1em;
}
#top-menu li li a {
  width: 200px;
  padding: 6px 20px;
}

#top-menu a {
  display: block;
  position: relative;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}

#top-menu > li:last-child {
  padding-right: 0px;
}

#et-info-email:hover,
#et-secondary-menu > ul > li > a:hover,
#top-menu-nav > ul > li > a:hover,
.et-social-icons a:hover {
  opacity: 0.7;
  transition: all 0.4s ease-in-out;
}

#top-menu a.active {
  color: $blue-2;
}

#top-menu {
  font-family: "Open Sans", Arial, sans-serif;
}

.nav ul li a:hover {
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.03);
}

#et_mobile_nav_menu {
  display: none;
}

@include for-divi-mobile {
  #top-menu {
    display: none;
  }
  #et_mobile_nav_menu {
    display: block;
    margin-top: 3px;
    font-weight: 600;
  }
  .mobile_menu_bar.mobile_menu_bar_toggle {
    top: -8px;
  }
  .mobile_nav {
    ul,
    ol {
      list-style: none;
      margin: 0;
      li {
        margin: 0;
        font-weight: 600;
      }
    }
  }
  .mobile_nav .select_page {
    display: none;
  }
  .mobile_menu_bar {
    &:before {
      color: $blue-2;
    }
  }
  .mobile_menu_bar {
    display: block;
    position: relative;
    line-height: 0;
  }
  .mobile_menu_bar:before {
    position: relative;
    top: 0;
    left: 0;
    font-size: 32px;
    content: "\61";
    cursor: pointer;
  }

  .et_mobile_menu {
    // display: none;
    visibility: visible;
    position: absolute;
    z-index: 9999;
    left: 0;
    width: 100%;
    padding: 5%;
    border-top: 3px solid $blue-2;
    opacity: 1;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 0;
  }

  .et_mobile_menu .menu-item-has-children > a {
    background-color: rgba(0, 0, 0, 0.03);
    font-weight: 700;
  }
  .et_mobile_menu li a {
    display: block;
    padding: 10px 5%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    color: #666;
  }

  .et_mobile_menu li a,
  .nav li li a {
    font-size: 14px;
    transition: all 0.2s ease-in-out;
  }
  #main-header .et_mobile_menu li ul,
  .et_pb_fullwidth_menu .et_mobile_menu li ul {
    display: block !important;
    visibility: visible !important;
    padding-left: 10px;
  }
  .et_mobile_menu li li {
    padding-left: 5%;
  }
  .et_mobile_menu li a:hover {
    opacity: 0.7;
    background-color: rgba(0, 0, 0, 0.03);
  }

  #mobile_menu {
    overflow-y: scroll;
    max-height: calc(100vh - 54px);
    padding-bottom: 130px;
  }
  #mobile_menu,
  #main-header .et_mobile_menu li ul,
  .et_pb_fullwidth_menu .et_mobile_menu li ul {
    opacity: 0;
    visibility: hidden !important;
    transition: opacity 0.3s, visibility 0.3s;
  }
  body.navIn {
    #mobile_menu,
    #main-header .et_mobile_menu li ul,
    .et_pb_fullwidth_menu .et_mobile_menu li ul {
      opacity: 1;
      visibility: visible !important;
    }
  }
}

et-info-email:before,
#et-info-phone:before,
#et_search_icon:before,
.comment-reply-link:after,
.et-cart-info span:before,
.et-pb-arrow-next:before,
.et-pb-arrow-prev:before,
.et-social-icon a:before,
.et_audio_container .mejs-playpause-button button:before,
.et_audio_container .mejs-volume-button button:before,
.et_overlay:before,
.et_password_protected_form .et_submit_button:after,
.et_pb_button:after,
.et_pb_contact_reset:after,
.et_pb_contact_submit:after,
.et_pb_font_icon:before,
.et_pb_newsletter_button:after,
.et_pb_pricing_table_button:after,
.et_pb_promo_button:after,
.et_pb_social_icon a.icon:before,
.et_pb_testimonial:before,
.et_pb_toggle_title:before,
.form-submit .et_pb_button:after,
.mobile_menu_bar:before,
.woocommerce #content input.button.alt:after,
.woocommerce #content input.button:after,
.woocommerce #respond input#submit.alt:after,
.woocommerce #respond input#submit:after,
.woocommerce a.button.alt:after,
.woocommerce button.button.alt:after,
.woocommerce button.button:after,
.woocommerce button.single_add_to_cart_button.button:after,
.woocommerce input.button.alt:after,
.woocommerce input.button:after,
.woocommerce-page #content input.button.alt:after,
.woocommerce-page #content input.button:after,
.woocommerce-page #respond input#submit.alt:after,
.woocommerce-page #respond input#submit:after,
.woocommerce-page a.button.alt:after,
.woocommerce-page a.button:after,
.woocommerce-page button.button.alt:after,
.woocommerce-page button.button:after,
.woocommerce-page input.button.alt:after,
.woocommerce-page input.button:after,
a.et_pb_more_button:after {
  text-shadow: 0 0;
  font-family: ETmodules !important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  text-transform: none;
  speak: none;
}

.nav li li ul {
  z-index: 1000;
  top: -23px;
  left: 240px;
}
.nav li.et-touch-hover > ul,
.nav li:hover > ul {
  visibility: visible;
  opacity: 1;
}
.nav li ul {
  transition: all 0.2s;
}
#top-menu > li > ul > li > a {
  padding-bottom: 6px !important;
}
#top-menu > li > ul > li > a::after {
  top: 6px !important;
}

.et_mobile_menu li .menu-item-has-children > a {
  background-color: transparent;
}
