/* main tags */
._entrance {
	padding:15px;
	margin-bottom:8px;
}
.entrance {
    width: 100%;
    height: 46px;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    border-radius: 5px;
    background: #4994c8;
    color:white;
}