.template.inner {
  .content { background-color:white; padding:60px;
    @include for-small-desktop { padding:40px 7vw }
    @include for-phone { padding:40px 5.5vw }
    .top { padding-bottom:30px; }
    .main.videos.false{ flex-direction: column;}
    .main { border-top:solid 1px $grey-50; padding-top:30px; display:flex; flex-direction:row-reverse; justify-content:space-between;
      > .text { padding-right:60px; max-width:560px; min-width:350px; flex-shrink:1;
        .body { 
          > * { max-width:100% }
          > *:first-child { margin-top:0 }
          video { max-width:100%; height:auto  }
          video, audio, img { margin-top:20px; outline:none }
        }
        .submit-wrap { position:relative; margin-top:30px; display:flex; align-items:center; 
          .button { display:flex; align-items:center; background-color:$blue-2; padding:8px 20px 8px 15px; border-radius:3px; transition:background-color.15s;
            &:hover { background-color:$blue-2-80 !important }
            &:disabled { background-color:$blue-2-80 !important; opacity:.8 !important }
            .icon { width:25px; margin-right:8px }
            p { margin-top:0; font-size:14px; font-weight:800; color:white }
          }
        }
        .protected-content {
          .locked { padding:16px; display:inline-block; background-color:$blue-2-15;
            > .inner { display:flex;
              .icon { width:19px; flex-shrink:0; margin-right:8px; margin-top:-2px }
              .text { 
                > *:first-of-type { margin-top:0; }
                p + p { margin-top:8px; }
                .link { margin-top:8px; }
              }
            }
          }  
        }
        .body + .protected-content { margin-top:40px }
      }
      .image, .video, .blog-links { width:300px; flex-shrink:0; flex-grow:1; max-width:450px; }
      .video {
        video { max-width:100%; outline:none }
      }
      @include for-small-desktop { 
        .text { max-width:none; min-width:0; padding-right:0 }
        .image, .video, .blog-links { float:right; width:50%; min-width:280px; max-width:none; padding:0 0 40px 40px }
      }
      @include for-ipad-v { display:flex; flex-direction:column;
        .image, .video, .blog-links { float:none; width:100%; min-width:0; padding-left:0; }
      }

      // .video { width:50%; flex-shrink:0; flex-grow:1; 
      // }
    }
  }
  &.inner-vodka-space,
  &.inner-research-institute {
    .content { 
      .main {
        .text { max-width:660px }
        @include for-small-desktop {
          .blog-links { width:auto }
        }
        @include for-ipad-v { flex-direction:column-reverse;
          .blog-links { margin-top:40px; padding-top:20px; padding-bottom:0; border-top:solid 1px $grey-50 }
        }
      }
    }
  }
}
iframe.product-doc { width:100%; height:600px; margin-top:0; border:0 }
// sp
@media screen and (max-width: 720px){
  .template.inner .content .main>.text .protected-content .locked{
      display: block;
  }
}