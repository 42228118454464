/* main tags */
.team {
	width:290px;
	padding:20px;
}
.team .title {
    font-size: 18px;
    color: #999999;
    line-height: 2em;
}
.team .text {
    font-size: 14px;
    color: #333333;
    line-height: 2em;
}
.team img {
	width:40%;
}