.articles {
    
}
.article {
    border-bottom: #e5e5e5 solid 1px;
    padding:15px  0px;
}
.article .title {
    font-size: 16px;
    color: #333333;
    line-height: 40px;
    height: 40px;
}
.article .title a {
    color:#333;
}
.article .parameters {
    width: 100%;
    font-size: 12px;
    color: #999999;
    line-height: 35px;
}
.article .parameters span {
    margin-right:8px;
}
/* attach */
.article .attach {
    display: flex;
    font-size: 14px;
    color: #6a7a86;
    padding:12px 0px;
}
.article .attach .collect {
    padding-right:10px;
    padding-left: 20px;
    background: url(/static/img/icon7.png) no-repeat 0 50%;
    padding-right: 20px;
}
.article .attach .tag {
    padding-left: 20px;
    background: url(/static/img/icon8.png) no-repeat 0 50%;
}
.article .attach .tag a {
    margin-right:8px;
}
/* introduction */
.article .introduction {
    width: 100%;
    font-size: 14px;
    color: #666666;
    line-height: 2em;
    padding: 10px 0 15px 0;
    border-bottom: #e5e5e5 solid 1px;
}
.article .introduction .link {
    font-size: 14px;
    color: #4994c8;
    line-height: 2em;
}
.article .text {
    width: 100%;
    line-height: 2em;
    padding-top:10px;
    text-align: justify;
    color: #6e7070;
    font-size: 14px;
}
.article .text p {
    color: #333;
    font-size: 14px;
}
.article .text .pretend {
    color:#4994c8;
}