footer {
    padding:20px 0px;
    margin-top:15px;
}
footer .foot {
    padding:20px 0px;
    font-size:14px;
}
footer .foot:first-child {
    border-bottom: #e5e5e5 solid 1px;
}
footer .email {
    background: url(/static/img/icon3.png) no-repeat 0 50%;
    padding-left: 42px;
    font-size: 16px;
    color: #666666;
    line-height: 40px;
}
footer .tel {
    background: url(/static/img/icon4.png) no-repeat 0 50%;
    padding-left: 42px;
    font-size: 16px;
    color: #666666;
    line-height: 40px;
}
footer .address {
    background: url(/static/img/icon5.png) no-repeat 0 50%;
    padding-left: 42px;
    font-size: 16px;
    color: #666666;
    line-height: 40px;
}
footer .service {
    width: 170px;
    height: 40px;
    border-radius: 40px;
    text-align: center;
    background: #4994c8;
    flex-shrink: 0;
    color: #FFFFFF;
    line-height: 40px;
}